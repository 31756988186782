import React, {useContext} from "react";

import {DeleteIcon, SearchIcon} from "@chakra-ui/icons";
import {IconButton, Stack, useDisclosure} from "@chakra-ui/react";
import {GuildContext} from "../../../../contexts/guild/GuildContext";
import toast from "react-hot-toast";
import {NavLink, useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {deleteScan} from "../../../../api/internal";


export default function ScansActionButtons({ value, column, row}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {id: serverId} = useContext(GuildContext);
    const auth0 = useAuth0();
    const navigate = useNavigate();

    function deleteScanView(sId){
        // post data to api (/scans/:guild/start)
        toast.promise(
            deleteScan(serverId, sId, auth0).then(r => {
                setTimeout(() => window.location.reload(), 1500);
            }),
            {
                loading: 'Deleting scan...',
                success: 'This scan have deleted.',
                error: 'Error while deleting the scan. Please report to server admin',
            }
        );
    }

    return (
        <Stack direction='row' spacing={2} justify="flex-end">
            <IconButton
                colorScheme='messenger'
                aria-label='See details'
                size='lg'
                className={"mx-1"}
                isDisabled={row.original.accounts === 0}
                onClick={() => {
                    navigate(`/guild/${serverId}/scan/${row.original.id}/details`)
                }}
                icon={<SearchIcon />}
            />
            <IconButton
                colorScheme='red'
                aria-label='Delete scan'
                size='lg'
                className={"mx-1"}
                isDisabled={row.original.state === 'SCANNING'}
                icon={<DeleteIcon />}
                onClick={() => deleteScanView(row.original.id)}
            />
        </Stack>
    )
}