import React from "react";

import {Spinner, Tag, TagLabel,} from "@chakra-ui/react";


export default function ScansStatus({ value, column, row}) {

    let color = 'green';
    let displayValue = value;

    switch (value) {
        case "SCANNING":
            color = 'orange';
            displayValue = 'Running...';
            break;
    }

    return (
        <Tag size={'lg'} key={'lg'} borderRadius='full' variant='solid' colorScheme={color}>
            { value === 'SCANNING' ? (<Spinner color='red.500' />): null}
            <TagLabel>{'   '}{displayValue}</TagLabel>
        </Tag>
    )
}