/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex, Grid, Icon, Skeleton, Text} from "@chakra-ui/react";

import {GuildContext} from "../../../../contexts/guild/GuildContext";
import {useLocale} from "../../../../utils/Language";
import {usePageInfo} from "../../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {getWebHooks} from "../../../../api/internal";
import {QueryHolder} from "../../../../contexts/components/AsyncContext";
import {Outlet} from "react-router-dom";
import Card from "../../../../components/card/Card";
import SearchTableUsers from "../../../../components/table/SearchTable2";
import WebHooksActionButtons from "../../../../components/table/cell/webhooks/WebHooksActionButtons";
import Owner from "../../../../components/table/cell/Owner";
import Channel from "../../../../components/table/cell/Channel";
import WebHooksType from "../../../../components/table/cell/webhooks/WebHooksType";
import {useAuth0} from "@auth0/auth0-react";

export default function WebHooksPanel() {
    const locale = useLocale()

    usePageInfo(locale({zh: "WebHooks", en: "WebHooks"}))

    return <WebHooksDisplay />
}

export function WebHooksDisplay() {
    const {id: serverId} = useContext(GuildContext)
    const auth0 = useAuth0();

    const webHooksQuery = useQuery(
        ["server_settings_webhooks", serverId],
        () => getWebHooks(serverId, auth0),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const columns = [
        {
            Header: "OWNER",
            accessor: "owner",
            Cell: Owner
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "CHANNEL",
            accessor: "channel",
            Cell: Channel
        },
        {
            Header: "WEBHOOK TYPE",
            accessor: "type",
            Cell: WebHooksType,
        },
        {
            Header: "WEBHOOKS ACTION",
            Cell: WebHooksActionButtons
        },
    ];

    console.log(webHooksQuery.isLoadingError, webHooksQuery);

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={webHooksQuery} nullable={true}>
                            {() =>
                                webHooksQuery.isLoading ? <Skeleton w="80%" h="5rem" rounded="xl" /> :
                                    ((webHooksQuery.isLoadingError || webHooksQuery.data.code) ?
                                        <Alert
                                            status='error'
                                            variant='subtle'
                                            flexDirection='column'
                                            alignItems='center'
                                            justifyContent='center'
                                            textAlign='center'
                                            height='200px'
                                        >
                                            <AlertIcon boxSize='40px' mr={0} />
                                            <AlertTitle mt={4} mb={1} fontSize='lg'>
                                                Permission denied!
                                            </AlertTitle>
                                            <AlertDescription maxWidth='sm'>
                                                The bot doesn't have the permission to see and manage WebHooks in this server. Please allow the permission MANAGE_WEBHOOKS.
                                            </AlertDescription>
                                        </Alert>  : <SearchTableUsers tableData={webHooksQuery.data ?? []} columnsData={columns}/>)
                            }
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            <Outlet/>
        </Box>
    );
}
