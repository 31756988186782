import {Select as BaseSelect} from "chakra-react-select";
import {Portal, useColorModeValue} from "@chakra-ui/react";

export function SelectField({value, onChange, options, ...props}) {
    const selected = Array.isArray(value) ? value.map(value => Array.isArray(options) &&
            options.find(option => option.value === value || (value?.includes(option.value)))) : Array.isArray(options) &&
        options.find(option => option.value === value || (value?.includes(option.value)))

    const brandColor = useColorModeValue("secondaryGray.300", "brand.400");
    const placeholderColor = useColorModeValue("gray.400", "gray.600");

    return <BaseSelect
        chakraStyles={{
            dropdownIndicator: (provided) => ({
                ...provided,
                background: "brand",
            }),

        }}
        focusBorderColor={brandColor}
        placeholderTextColor={placeholderColor} // Définir la couleur du texte du placeholder
        selectedOptionStyle="check"
        color={"white"}
        position="absolute" // Modifier la position du menu déroulant
        {...props}
        value={selected}
        onChange={e =>{
            onChange != null && onChange(
                Array.isArray(e)? e.map(option => option.value) : e.value
            );
        }
        }
        options={options}
    />
}