/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Box, Button, Flex, Skeleton, Stack, Stat, StatNumber, useColorModeValue,} from "@chakra-ui/react";

// Custom components
import {GuildContext} from "../../../contexts/guild/GuildContext";
import {useLocale} from "../../../utils/Language";
import {usePageInfo} from "../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {FiPlus} from 'react-icons/fi'
import {getWaitingScans} from "../../../api/internal";
import {QueryHolder} from "../../../contexts/components/AsyncContext";
import {NavLink} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import SearchTableUsers from "../../../components/table/SearchTable2";
import Card from "../../../components/card/Card";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import ScansActionButtons from "../../../components/table/cell/scans/ScansActionButtons";
import ScansStatus from "../../../components/table/cell/scans/ScansStatus";

export default function ScanPanel() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Manage scans", en: "Manage scans"}))

    return <ScanDisplay />
}

export function ScanDisplay() {
    const {id: serverId} = useContext(GuildContext)
    const auth0 = useAuth0();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    // Chakra Color Mode

    const scanHistoryQuery = useQuery(
        ["server_scan_history", serverId],
        () => getWaitingScans(serverId, auth0),
    );

    const columns = [
        {
            Header: "ID",
            accessor: 'id',
        },
        {
            Header: "STATS",
            accessor: 'state',
            Cell: ScansStatus
        },
        {
            Header: "ACCOUNTS",
            accessor: 'accounts',
            Cell: ({ value, column, row}) => {
                return (
                    <Stat>
                        <StatNumber color={textColor} >{value}</StatNumber>
                    </Stat>
                )
            }
        },
        {
            Header: "DATE",
            accessor: 'createdAt',
        },
        {
            Header: "ACTIONS",
            Cell: ScansActionButtons
        },
    ];

    const customRight = (
        <Stack direction='row' spacing={4}>
            <NavLink to="create">
                <Button leftIcon={<FiPlus/>} h='44px' w={{ lg: "200px" }} colorScheme={'whatsapp'} mx={"auto"}>
                    Create new scan
                </Button>
            </NavLink>
            <NavLink to="history">
                <Button rightIcon={<ArrowForwardIcon />} h='44px' w={{ lg: "200px" }} colorScheme={'facebook'}  mx={"auto"}>
                    See full history
                </Button>
            </NavLink>
        </Stack>

    )

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={scanHistoryQuery}>
                            {() =>
                                scanHistoryQuery.isLoading ? <Skeleton w="80%" h="5rem" rounded="xl" /> :
                                    <SearchTableUsers customRightSide={customRight} tableData={scanHistoryQuery.data} columnsData={columns}/>
                            }
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            {/* Delete Product */}
        </Box>
    );
}
