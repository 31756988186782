/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Box, Flex, Select} from "@chakra-ui/react";

import {GuildContext} from "../../../../contexts/guild/GuildContext";
import {useLocale} from "../../../../utils/Language";
import {usePageInfo} from "../../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {getMembers} from "../../../../api/internal";
import {QueryHolder} from "../../../../contexts/components/AsyncContext";
import {Outlet} from "react-router-dom";
import Card from "../../../../components/card/Card";
import SearchTableUsers from "../../../../components/table/SearchTable2";
import ServerRoles from "../../../../components/table/cell/roles/ServerRoles";
import {useAuth0} from "@auth0/auth0-react";
import Member from "../../../../components/table/cell/Member";
import Date from "../../../../components/table/cell/Date";
import type {FilterElementProps} from "../../../../components/table/interfaces";

export default function MembersPanel() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Members", en: "Members"}))

    return <MembersDisplay />
}

export function MembersDisplay() {
    const {id: serverId} = useContext(GuildContext)
    const auth0 = useAuth0();
    // Chakra Color Mode

    const membersQuery = useQuery(
        ["server_members_query", serverId],
        () => getMembers(serverId, auth0),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const columns = [
        {
            Header: "AVATAR",
            accessor: "displayAvatarURL",
            Cell: Member,
            canSort: false,
            minWidth: 200,
        },
        {
            Header: "ROLES",
            accessor: "serverRoles",
            Cell: ServerRoles,
            enableColumnFilter: true,
            meta: {
                filterElement: function render(props: FilterElementProps) {
                    return (
                        <Select borderRadius="md" size="sm" placeholder="@everyone"{...props} >
                        </Select>
                    );
                },
                filterOperator: "eq",
            },
        },
        {
            Header: "JOIN DATE",
            accessor: 'joinedTimestamp',
            Cell: Date
        },
    ];

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={membersQuery}>
                            <SearchTableUsers tableData={membersQuery.data} columnsData={columns}/>
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            <Outlet/>
        </Box>
    );
}
