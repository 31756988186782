import React from "react";

import {Text} from "@chakra-ui/react";


export default function Date({ value, column, row}) {
    try {
        return (
            <Text fontSize='md' fontWeight='500'>
                {new Date(value).toLocaleString()}
            </Text>
        )
    } catch (e) {
        return <></>
    }
}