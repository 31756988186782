import React from "react";

import {Avatar, AvatarGroup} from "@chakra-ui/react";

export default function Members({ value, column, row}) {
    return (
        <AvatarGroup size="sm">
            {value.map((member) => {
                return (
                    <Avatar key={member} src={member} _hover={{ zIndex: "3", cursor: "pointer" }}/>
                );
            })}
        </AvatarGroup>
    )
}