// Chakra imports
import {
    Box,
    Flex,
    Text,
    Icon,
    Link,
    Image,
    useColorModeValue,
    SimpleGrid,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { CircProgressMini } from "components/charts/CircularProgress";
// Assets
import { MdFlag, MdCheck, MdChevronRight, MdLock } from "react-icons/md";
import React from "react";
import { VSeparator } from "components/separator/Separator";

export default function CourseInfo(props) {
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "brand.400");
    const bgBar = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
    const bg = useColorModeValue("secondaryGray.300", "navy.700");
    const textColorSecondary = useColorModeValue(
        "secondaryGray.600",
        "secondaryGray.500"
    );
    const textColorTertiary = useColorModeValue("secondaryGray.600", "white");
    const { query, ...rest } = props;

    const MAX_TASKS = 6;
    const completed = 0;


    return (
        <Card {...rest} maxH='max-content'>
            <Text color={textColor} fontSize='xl' fontWeight='700' mb='16px'>
                Check-list: Complete Guide
            </Text>
            <Flex alignItems='center' mb='20px'>
                <Text color={textColorSecondary} fontSize='md' fontWeight='700'>
                    {completed}/{MAX_TASKS} COMPLETED
                </Text>
                <Icon ms='auto' as={MdFlag} color='green.500' h='18px' w='18px' />
            </Flex>
            <SimpleGrid columns={MAX_TASKS} gap='5px' alignItems='center' mb='20px'>
                {Array.from({ length: MAX_TASKS }, (_, index) => (
                    <Flex bg={completed > index ? 'green.500' : bgBar} h='8px' borderRadius='36px' />
                ))}
            </SimpleGrid>
            <Flex mb='25px' align='center' cursor='pointer'>
                <Box w='30px' me='12px'>
                    <CircProgressMini step='1' percentage={23} />
                </Box>
                <Text color={"green.500"} fontWeight='500' fontSize='md' me='5px'>
                    Run your first scan
                </Text>
            </Flex>
            <Flex mb='25px' align='center' cursor='pointer'>
                <Box w='30px' me='12px'>
                    <CircProgressMini step='2' percentage={0} />
                </Box>
                <Text color={textColor} fontWeight='500' fontSize='md' me='5px'>
                    Create your first filters in Filters
                </Text>
            </Flex>
            <Flex mb='25px' align='center' cursor='pointer'>
                <Box w='30px' me='12px'>
                    <CircProgressMini step='3' percentage={0} />
                </Box>
                <Text color={textColor} fontWeight='500' fontSize='md' me='5px'>
                    Create your first server backup in Backups
                </Text>
            </Flex>
            <Flex mb='25px' align='center' cursor='pointer'>
                <Box w='30px' me='12px'>
                    <CircProgressMini step='4' percentage={0} />
                </Box>
                <Text color={textColor} fontWeight='500' fontSize='md' me='5px'>
                    Create your first report
                </Text>
            </Flex>
            <Flex mb='25px' align='center' cursor='pointer'>
                <Box w='30px' me='12px'>
                    <CircProgressMini step='5' percentage={0} />
                </Box>
                <Text color={textColor} fontWeight='500' fontSize='md' me='5px'>
                    Setup Whitelist roles in Settings
                </Text>
            </Flex>
            <Flex mb='25px' align='center' cursor='pointer'>
            <Box w='30px' me='12px'>
                <CircProgressMini step='6' percentage={0} />
            </Box>
            <Text color={textColor} fontWeight='500' fontSize='md' me='5px'>
                Upgrade your server to Premium version
            </Text>
        </Flex>
        </Card>
    );
}
