import React, {useEffect} from "react";
import ReactDOM from 'react-dom';
import "./assets/css/App.css";
import {BrowserRouter, Navigate, Route, Routes,} from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import StaffLayout, {StaffRoutes} from "./layouts/staff";
import GuildLayout, {GuildRoutes} from "./layouts/guild";
import {Center, ChakraProvider, Spinner, Stack, Text, useToast} from "@chakra-ui/react";
import customTheme from "./theme/theme";
import {QueryClient, QueryClientProvider} from 'react-query'

import {SettingsProvider} from "./contexts/SettingsContext";
import ScanPanelDetails from "./views/guild/scan/details";
import ScanPanelHashDetails from "./views/guild/scan/details/hash";
import Maintenance from "./views/info/Maintenance";
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import NotFound from "./views/info/Not_Found";
import ReactGA from "react-ga4";

ReactGA.initialize("G-627VTJFY33");

ReactDOM.render(
    <Auth0Provider
        domain={"dev-ok5gnhwmvmabwexo.us.auth0.com"}
        clientId={"9l2qUQCyewNfkrS2HILpvO25YdymwLeU"}
        //useRefreshTokens={true}
        cacheLocation={'localstorage'}
        cookieDomain={"banwaves.xyz"}
        authorizationParams={{
            connection: "Discord",
            redirect_uri: window.location.origin,
            audience: "https://internal.api.banwaves.xyz"

        }}
    >
        <React.StrictMode>
            <title itemProp="name">BanWaves - Dashboard</title>
            <ChakraProvider theme={customTheme}>
                <QueryClientProvider client={new QueryClient()}>
                    <SettingsProvider>
                        <BrowserRouter>
                                <AppRouter/>
                        </BrowserRouter>
                    </SettingsProvider>
                </QueryClientProvider>
            </ChakraProvider>
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById("root")
);

function AppRouter() {
    const auth0 = useAuth0();
    const toast = useToast();

    const {isAuthenticated, isLoading} = auth0;

    if(isLoading){
        return (
            <Center height="100vh">
                <Stack direction="column" align="center">
                    <Spinner size="lg"/>
                    <Text>Loading...</Text>
                </Stack>
            </Center>
        );
    }

    return (
        <Routes>
            <Route path={ "/maintenance" } element={<Maintenance />} />
            <Route path={ "/404" } element={<NotFound />} />
            <Route path="/invite" element={ <Redirect url={process.env.DISCORD_APP_INVITE}/> }/>

            {isAuthenticated && (
                <>
                    <Route path={`/admin`} element={<AdminLayout/>}/>

                    <Route path={"/staff/*" } element={<StaffLayout/>}>
                        { StaffRoutes() }
                    </Route>

                    <Route path="/guild/:id/*" element={<GuildLayout/>}>
                        <Route path="scan/:sId/details" element={<ScanPanelDetails/>}/>
                        <Route path="scan/:sId/details/hash/:hashId" element={<ScanPanelHashDetails/>}/>
                        {GuildRoutes()}
                    </Route>

                    <Route path="*" element={
                        <Navigate replace to="/admin"/>
                    }/>
                </>
            )}

            {!isAuthenticated && (
                <>
                    <Route path={`/auth`} element={<AuthLayout isCallback/>}/>
                    <Route path={`/signin`} element={<AuthLayout/>} exact/>
                    <Route path="*" element={
                        <Navigate replace to="/signin"/>
                    }/>
                </>
            )}
        </Routes>
    );
}

function Redirect({url}) {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return <Center height="100vh">
        <Stack direction="column" align="center">
            <Spinner size="lg"/>
            <Text>Loading...</Text>
        </Stack>
    </Center>;
}