/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Box, Flex, Skeleton} from "@chakra-ui/react";

import {GuildContext} from "../../../../contexts/guild/GuildContext";
import {useLocale} from "../../../../utils/Language";
import {usePageInfo} from "../../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {getInvites} from "../../../../api/internal";
import {QueryHolder} from "../../../../contexts/components/AsyncContext";
import {Outlet} from "react-router-dom";
import Card from "../../../../components/card/Card";
import SearchTableUsers from "../../../../components/table/SearchTable2";
import {useAuth0} from "@auth0/auth0-react";

export default function InvitesPanel() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Invites", en: "Invites"}))

    const {id: serverId} = useContext(GuildContext)
    const auth0 = useAuth0();
    // Chakra Color Mode

    const ApiQuery = useQuery(
        ["server_members_query", serverId],
        () => getInvites(serverId, auth0),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const columns = [
        {
            Header: "CODE",
            accessor: "code",
        },
        {
            Header: "UserId",
            accessor: "inviterId",
        },
        {
            Header: "CREATED DATE",
            accessor: 'createdTimestamp',
        },
        {
            Header: "EXPIRATION DATE",
            accessor: 'expiresTimestamp',
        },
    ];

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={ApiQuery}>
                            {() =>
                                ApiQuery.isLoading ? <Skeleton w="80%" h="5rem" rounded="xl" /> : <SearchTableUsers tableData={ApiQuery.data} columnsData={columns}/>
                            }
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            <Outlet/>
        </Box>
    );
}
