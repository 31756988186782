/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Box, Button, Flex, Skeleton, Stack, Stat, StatNumber, useColorModeValue} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import {GuildContext} from "../../../../contexts/guild/GuildContext";
import {useLocale} from "../../../../utils/Language";
import {usePageInfo} from "../../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {FiPlus} from 'react-icons/fi'
import {getLastScans} from "../../../../api/internal";
import {QueryHolder} from "../../../../contexts/components/AsyncContext";
import {NavLink} from "react-router-dom";
import ScansStatus from "../../../../components/table/cell/scans/ScansStatus";
import ScansActionButtons from "../../../../components/table/cell/scans/ScansActionButtons";
import SearchTableUsers from "../../../../components/table/SearchTable2";
import {useAuth0} from "@auth0/auth0-react";

export default function ScanPanelHistory() {
    const locale = useLocale()

    usePageInfo(locale({zh: "服務器儀表板", en: "Scan history"}))


    return <ScanHistory />
}

export function ScanHistory() {
    const {id: serverId} = useContext(GuildContext)
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const auth0 = useAuth0();

    const scanHistoryQuery = useQuery(
        "server_scan_history",
        () => getLastScans(serverId, auth0, 500),
    );

    const columns = [
        {
            Header: "ID",
            accessor: 'id',
        },
        {
            Header: "STATS",
            accessor: 'state',
            Cell: ScansStatus
        },
        {
            Header: "ACCOUNTS",
            accessor: 'accounts',
            Cell: ({ value, column, row}) => {
                return (
                    <Stat>
                        <StatNumber color={textColor} >{value}</StatNumber>
                    </Stat>
                )
            }
        },
        {
            Header: "DATE",
            accessor: 'createdAt',
        },
        {
            Header: "ACTIONS",
            Cell: ScansActionButtons
        },
    ];

    const customRight = (
        <Stack direction='row' spacing={4}>
            <NavLink to="../scan/create">
                <Button leftIcon={<FiPlus/>} h='44px' w={{ lg: "200px" }} colorScheme={'whatsapp'} mx={"auto"}>
                    Create new scan
                </Button>
            </NavLink>
        </Stack>
    )



    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={scanHistoryQuery}>
                            {() =>
                                scanHistoryQuery.isLoading ? <Skeleton w="80%" h="5rem" rounded="xl" /> :
                                    <SearchTableUsers customRightSide={customRight} tableData={(scanHistoryQuery.data?.error ? [] : scanHistoryQuery.data)} columnsData={columns}/>
                            }
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            {/* Delete Product */}
        </Box>
    );
}
