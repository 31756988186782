import React from 'react'
import {classNames} from './Utils'
import {useColorMode} from "@chakra-ui/react";

export function Button({ children, className, ...rest }) {
    return (
        <button
            type="button"
            className={
                classNames(
                    "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
                    className
                )}
            {...rest}
        >
            {children}
        </button>
    )
}

export function PageButton({ children, className, ...rest }) {
    const { colorMode } = useColorMode();

    return (
        <button
            type="button"
            className={
                classNames((colorMode === "light") ? "border-gray-300 text-gray-500 bg-white hover:bg-gray-50" : "border-[#323252] text-gray-500 bg-[#292e45] hover:bg-[#323252]",
                    "relative inline-flex items-center px-2 py-2 border text-sm font-medium transition",
                    className
                )}
            {...rest}
        >
            {children}
        </button>
    )
}