import React, {lazy} from "react";

import {Icon} from "@chakra-ui/react";
import {Navigate} from "react-router-dom";
import {BiBody, BiCategory, BiCreditCardAlt, BiError, BiListUl, BiRun} from "react-icons/bi";
import {BsDiscord} from "react-icons/bs";
import {TbWebhook} from "react-icons/tb";
import { FaBlogger } from "react-icons/fa";


import {IoIosSettings} from "react-icons/io";
// Admin Imports
import Dashboard from "./views/guild/dashboard";
import ScanBoard from "./views/guild/scan";
import AntiDMScamSettingsPanel from "./views/guild/settings/antidmscam";
import ScanPanelHistory from "./views/guild/scan/history";
import ScanCreationPanel from "./views/guild/scan/create";
import BanPanel from "./views/guild/discord/bans";
import MembersPanel from "./views/guild/discord/members";
import WebHooksPanel from "./views/guild/discord/webhooks";
import FiltersPanel from "./views/guild/filters";
import InvitesPanel from "./views/guild/discord/invites";
import AutoModConfigPanel from "./views/guild/settings/automod";
import LoggerPanel from "./views/guild/logger";

const SettingsPanel= lazy(() => import("./views/guild/settings"))
const PremiumPanel = lazy(() => import("./views/guild/premium"))
/**
 * Public Routes that can access on sidebar
 * Path variables are not allowed (ex: feature/:id)
 */
const routes = [
    {
        name: {zh: "服務器儀表板", en: "Dashboard"},
        icon: <Icon as={BiCategory} width="20px" height="20px" color="inherit"/>,
        path: "dashboard",
        component: <Dashboard/>,
    },
    
    {
        name: {zh: "服務器儀表板", en: "Manage scans"},
        icon: <Icon as={BiRun} width="20px" height="20px" color="inherit"/>,
        path: "scan",
        component: <ScanBoard/>,
        items: [ {name: 'Start a new scan', path: 'create'}, {name: 'History', path: 'history'} ],
        children: [{ path: "scan/history", component: <ScanPanelHistory/> }, { path: "scan/create", component: <ScanCreationPanel/> }]
    },
    {
        name: {zh: "Filters", en: "Filters"},
        icon: <Icon as={BiCreditCardAlt} width="20px" height="20px" color="inherit"/>,
        path: "filters",
        premium: true,
        component: <FiltersPanel/>,
    },
    {
        name: {zh: "Logger", en: "Logger"},
        icon: <Icon as={FaBlogger} width="20px" height="20px" color="inherit"/>,
        path: "logger",
        premium: false,
        component: <LoggerPanel/>,
    },
    /*{
        name: {zh: "Backups", en: "Backups"},
        icon: <Icon as={MdBackup} width="20px" height="20px" color="inherit"/>,
        path: "backups",
        premium: true,
        component: <BackupsPanel/>,
        children: [
            {
                path: "backups/:backupId",
                component: (<BackupViewPanel />)
            }
        ]
    },*/
    {
        name: {zh: "Discord", en: "Discord"},
        icon: (
            <Icon as={BsDiscord} width="20px" height="20px" color="inherit"/>
        ),
        component:  <Navigate to="members"/>,
        path: "discord",
        items: [
            {
                name: 'Members',
                path: 'members',
                icon: (<Icon as={BiBody}/>),
            },
            {
                name: 'Bans',
                path: 'bans',
                icon: (<Icon as={BiListUl}/>),
            },
            {
                name: 'WebHooks',
                path: 'webhooks',
                icon: (<Icon as={TbWebhook}/>),
                premium: true
            },
            {
                name: 'Invites',
                path: 'invites',
                icon: (<Icon as={TbWebhook}/>),
                premium: true
            },
        ],
        children: [
            { path: "discord/members", component: <MembersPanel/> },
            { path: "discord/bans", component: <BanPanel/> },
            { path: "discord/webhooks", component: <WebHooksPanel/> },
            { path: "discord/invites", component: <InvitesPanel/> }
        ]
    },


    {
        name: {zh: "Settings", en: "Settings"},
        icon: (
            <Icon as={IoIosSettings} width="20px" height="20px" color="inherit"/>
        ),
        component:  <Navigate to="serversettings"/>,
        path: "settings",
        items: [
            {
                name: 'Server Settings',
                path: 'serversettings',
                icon: (<Icon as={IoIosSettings}/>),
            },
            {
                name: 'Auto Cleanse',
                path: 'autocleanse',
                icon: (<Icon as={BiError}/>),
                premium: true
            },
            {
                name: 'Auto Mod',
                path: 'automod',
                icon: (<Icon as={BiError}/>),
            }
        ],
        children: [
            { path: "settings/serversettings", component: <SettingsPanel/> },
            { path: "settings/autocleanse", component: <AntiDMScamSettingsPanel/> },
            { path: "settings/automod", component: <AutoModConfigPanel/> },
        ]
    },
    {
        name: {zh: "服務器儀表板", en: "Subscription"},
        icon: <Icon as={BiCreditCardAlt} width="20px" height="20px" color="inherit"/>,
        path: "premium",
        component: <PremiumPanel/>,
    },
];

export default routes;
