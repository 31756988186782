import {IconButton} from "@chakra-ui/react";

import {ColumnButtonProps} from "../interfaces";
import {BiChevronDown,} from "react-icons/bi";
import {HiOutlineSelector} from "react-icons/hi";

export const ColumnSorter: React.FC<ColumnButtonProps> = ({ column }) => {
    /*if (!column.enableSorting) {
        return null;
    }*/

    const sorted = column.isSorted;

    return (
        <IconButton
            aria-label="Sort"
            size="xs"
            style={{
                transition: "transform 0.25s",
                transform: `rotate(${column.isSortedDesc ? "0" : "180"}deg)`,
            }}
            variant={sorted ? "light" : "transparent"}
            color={sorted ? "primary" : "gray"}
        >
            {sorted ? (
                <BiChevronDown size={18} onClick={() => column.toggleSortBy()} />
            ) : (
                <HiOutlineSelector size={18} onClick={() => column.toggleSortBy()} />
            )}
        </IconButton>
    );
};
