import {createContext, useContext} from "react";
import {QueryHolder} from "../components/AsyncContext";
import {getSettings} from "../../api/internal";
import {GuildContext} from "./GuildContext";
import {useQuery} from "react-query";
import {useAuth0} from "@auth0/auth0-react";

export const SettingsContext = createContext({
    values: null
})

export function SettingsProvider({children}) {
    const {id: serverId} = useContext(GuildContext);
    const auth0 = useAuth0();

    const query = useQuery(["settings", serverId], () =>
        getSettings(serverId, auth0)
    )

    return <QueryHolder query={query}>
        <SettingsContext.Provider value={query.data}>
            {children}
        </SettingsContext.Provider>
    </QueryHolder>
}