// Chakra imports
import {Box, Button, Center, Flex, Icon, Image, Stack, Text, useColorModeValue,} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import React from "react";
import {MdUpgrade} from "react-icons/md";

export default function Course(props) {
    const { title, desc, buttons, imageUrl } = props;
    const textColor = useColorModeValue("navy.700", "white");
    const textBrand = useColorModeValue("brand.500", "white");
    const bgBadge = useColorModeValue("secondaryGray.300", "whiteAlpha.50");
    return (
        <Card p='20px' h='max-content' minH={{ md: "450px", xl: "auto" }}>
            <Flex direction={{ base: "column", md: "column", xl: "row" }}>
                <Box
                    rounded="md"
                    me='14px'
                    h={{ base: "270px", xl: "270px" }}
                    w={{ base: "270px", xl: "270px" }}
                    minW={'270px'}
                    overflow="hidden"
                >
                    {imageUrl?
                        <Image draggable={false} minW={'full'} minH={'full'} w={'full'} h={'full'} src={imageUrl}/> :
                        <ItemIcon />
                    }
                </Box>
                <Flex
                    justify='space-between'
                    flexDirection='column'
                    mb='auto'
                    py='30px'
                    pb={{ base: "0px", md: "0px" }}>
                    <Flex display={{ base: "block", xl: "flex" }}>
                        <Box direction='column' w={{ xl: "68%" }} mb='25px'>
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "xl",
                                    md: "xl",
                                    xl: "xl",
                                    "2xl": "28px",
                                }}
                                mb='10px'
                                fontWeight='700'>
                                {title}
                            </Text>
                            <Text
                                color='secondaryGray.600'
                                fontSize={{
                                    base: "md",
                                }}
                                fontWeight='400'
                                me='14px'>
                                {desc}
                            </Text>
                        </Box>
                    </Flex>
                    <Stack direction='row' spacing={4} align='center'>
                        {buttons?.map((topic, key) => (
                            <a href={topic.url} target={topic.target}>
                                <Button isDisabled={!topic.enabled} variant={topic.type === 'link' ? 'link': 'outline'} colorScheme={topic.color}>{topic.title}</Button>
                            </a>
                        ))}
                    </Stack>
                </Flex>
            </Flex>
        </Card>
    );
}

function ItemIcon() {
    return <Center
        h="full"
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'>
        <Icon as={MdUpgrade} color='white' w={8} h={14} />
    </Center>
}