import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import React, {useContext} from "react";
import {GuildContext} from "../../contexts/guild/GuildContext";
import {useQuery} from "react-query";
import {getNotifications} from "../../api/internal";
import {MdNotificationsNone} from "react-icons/md";
import {Locale} from "../../utils/Language";
import {QueryHolderSkeleton} from "../../contexts/components/AsyncContext";
import {NotificationItem} from "./NotificationItem";
import {useCardBg, useTextColor} from "../../utils/colors";
import {useAuth0} from "@auth0/auth0-react";

export function Notifications() {
    const navbarIcon = useColorModeValue("gray.400", "white");
    const textColor = useTextColor();
    const menuBg = useCardBg()
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    const {isOpen, onOpen, onClose} = useDisclosure()
    const auth0 = useAuth0();

    const {id: serverId} = useContext(GuildContext)
    const query = useQuery(
        ["notifications", serverId],
        () => getNotifications(serverId, auth0),
        {
            enabled: isOpen
        },
    );

    return <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton p="0px" onClick={onOpen}>
            <Icon
                mt={"5px"}
                ml={"5px"}
                w={5}
                h={5}
                as={MdNotificationsNone}
                color={navbarIcon}
            />
        </MenuButton>
        <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{base: "30px", md: "unset"}}
            minW={{base: "80vw", md: "400px", xl: "450px"}}
            maxW={{base: "360px", md: "unset"}}
        >
            <Text w="100%" mb="20px" fontSize="md" fontWeight="600" color={textColor}>
                <Locale zh="通知" en="Notifications" />
            </Text>
            <Flex direction="column" gap={3}>
                <QueryHolderSkeleton query={query} height="100px" count={2}>
                    { !query.data || query.data.length < 1 ? (
                            <Alert status='success'>
                                <AlertIcon />
                                <Box>
                                    <AlertTitle>All clear!</AlertTitle>
                                    <AlertDescription>
                                        No new notifications. 🌟
                                    </AlertDescription>
                                </Box>
                            </Alert>
                    ) : query.data.map((item, key) => (
                            <MenuItem key={key} borderRadius="8px" p={0}>
                                <NotificationItem {...item} />
                            </MenuItem>
                    )) }
                </QueryHolderSkeleton>
            </Flex>
        </MenuList>
    </Menu>
}