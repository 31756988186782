import React, {useContext} from "react";

import {DeleteIcon} from "@chakra-ui/icons";
import {IconButton, Stack} from "@chakra-ui/react";
import {GuildContext} from "../../../../contexts/guild/GuildContext";
import toast from "react-hot-toast";
import {useAuth0} from "@auth0/auth0-react";
import * as guild from "../../../../api/internal";
import EditFilterModal from "../../../../views/guild/filters/component/EditFilterModal";


export default function FiltersActionButtons({ value, column, row}) {
    const {id: serverId} = useContext(GuildContext);
    const auth0 = useAuth0();

    const deleteAction = (filterId) => {
        toast.promise(
            guild.deleteFilters(serverId, filterId, auth0).then(r => {
                setTimeout(() => window.location.href = `/guild/${serverId}/filters`, 500);
            }),
            {
                loading: 'Deleting filter...',
                success: 'Filter deleted!',
                error: 'Error deleted filter',
            }
        );
    }

    return (
        <Stack direction='row' spacing={2} justify="flex-end">
            <EditFilterModal serverId={serverId} filterId={row.original.id}/>
            <IconButton
                colorScheme='red'
                aria-label='Delete'
                size='lg'
                className={"mx-1"}
                icon={<DeleteIcon />}
                onClick={ () => deleteAction(row.original.id)}
            />
        </Stack>
    )
}