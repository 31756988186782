import {createContext} from "react";
import {getAccountInfo} from "api/discord/DiscordApi";
import {QueryHolder} from "./components/AsyncContext";
import {useQuery} from "react-query";
import {useAuth0} from '@auth0/auth0-react';

export const UserDataContext = createContext({});

export function UserDataProvider({children}) {
    const auth0 = useAuth0();
    const query = useQuery("user_data",
        () => getAccountInfo(auth0),
        {
            refetchOnWindowFocus: false
        }
    );

    return (
        <QueryHolder query={query}>
            <UserDataContext.Provider value={query.data}>
                {children}
            </UserDataContext.Provider>
        </QueryHolder>
    );
}
