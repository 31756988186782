import React, {useContext, useMemo} from "react";

import {Box} from "@chakra-ui/react";

import {usePageInfo} from "contexts/PageInfoContext";
import {SettingsContext, SettingsProvider} from "contexts/guild/GuildSettingsContext";
import {GuildContext} from "contexts/guild/GuildContext";
import {ConfigGrid} from "components/fields/ConfigPanel";
import {updateSettingsOptions} from "api/internal";
import {config} from "config/config";
import {useQueryClient} from "react-query";
import {useLocale} from "utils/Language";
import {useAuth0} from "@auth0/auth0-react";

export default function AntiDMScamSettingsPanel() {
    const locale = useLocale()
    usePageInfo(
        locale({zh: "Auto Cleanse", en: "Auto Cleanse"})
    )

    return (
        <SettingsProvider>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                <AntiDmScamSettingsConfigPanel />
            </Box>
    </SettingsProvider>
  );
}

function AntiDmScamSettingsConfigPanel() {
    const settings = useContext(SettingsContext);

    const {id: serverId} = useContext(GuildContext);
    const client = useQueryClient()
    const auth0 = useAuth0();

    const onSave = (changes) => updateSettingsOptions(serverId, changes, auth0)

    const onSaved = data => client.setQueryData(["settings", serverId], {
        ...settings,
        values: data
    })

    const options = useMemo(
        () => config.antiscamdm(settings.values),
        [settings.values]
    )

    return (
        <ConfigGrid options={options} onSave={onSave} onSaved={onSaved} />
    )
}