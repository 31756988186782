import React from "react";
import {Badge} from "@chakra-ui/react";


export default function ServerRoles({ value, column, row} : cell) {
    return (
        <>
            {value.map((role) => {
                if(value.length > 1 && role.name === '@everyone') return;

                const color = role.color.toString(16).toUpperCase();
                return( <Badge key={role?.id} variant='solid' backgroundColor={'#' + color} className={"m-1"} textColor={'white'} textAlign={"center"}>{role?.name}</Badge> )})
            }
        </>
    )
}