import React from "react";
import {Badge} from "@chakra-ui/react";

export default function FiltersType({ value, column, row}) {
    if(value === "ban"){
        return (<Badge colorScheme="red">Ban</Badge>)
    } else if(value === "kick"){
        return (<Badge colorScheme="yellow">Kick</Badge>)
    } else if(value === "warn") {
        return (<Badge colorScheme="blue">Warn</Badge>)
    }
}