const fetch = require('node-fetch');

export const fetchAuto = async (url, auth, {toJson = false, throwError = true, ...options} = {}) => {
    try {
        const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
        const accessToken = await auth.getAccessTokenSilently({audience});

        return fetch(`${process.env.REACT_APP_SERVER_URL}${url}`, {
            ...options,
            headers: {
                ...options.headers,
                // Add the Authorization header to the existing headers
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }).then(async res => {
            let mapper

            if (toJson) {
                mapper = res => res.json()
            } else {
                mapper = res => res.text().then(() => res)
            }

            return mapper ? mapper(res) : res
        }).catch(err => {
            console.log(err);

            return [];
        })
    } catch (error) {
        console.log(error);

        return [];
    }
}