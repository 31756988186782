import React, {useState} from "react";
import {ColumnButtonProps} from "../interfaces";
import {HStack, IconButton, Input, Menu, MenuButton, MenuList, VStack} from "@chakra-ui/react";
import {TbFilter} from "react-icons/tb";
import {AiFillCheckCircle} from "react-icons/ai";
import {MdCancel} from "react-icons/md"

export const ColumnFilter: React.FC<ColumnButtonProps> = ({ column }) => {
    // eslint-disable-next-line
    const [state, setState] = useState();

    if (!column.enableColumnFilter) {
        return null;
    }

    const open = () =>
        setState({
            value: column.filterValue,
        });

    const close = () => setState(null);

    // eslint-disable-next-line
    const change = (value: any) => setState({ value });

    const clear = () => {
        column.setFilter(undefined);
        close();
    };

    const save = () => {
        if (!state) return;
        column.setFilter(state.value);
        close();
    };

    const renderFilterElement = () => {
        // eslint-disable-next-line
        const FilterComponent = column.meta?.filterElement;

        if (!FilterComponent && !!state) {
            return (
                <Input
                    borderRadius="md"
                    textColor={'white'}
                    size="sm"
                    autoComplete="on"
                    value={state.value}
                    onChange={(e) => change(e.target.value)}
                />
            );
        }

        return (
            <FilterComponent
                value={state?.value}
                onChange={(e: any) => change(e.target.value)}
            />
        );
    };

    const filtered = column.filterValue;

    return (
        <Menu isOpen={!!state} onClose={close}>
            <MenuButton
                onClick={open}
                as={IconButton}
                aria-label="Options"
                variant={filtered ? "light" : "transparent"}
                color={filtered ? "primary" : "gray"}
                size="xs">
                <TbFilter size={18} onClick={open} />
            </MenuButton>
            <MenuList p="2">
                {!!state && (
                    <VStack align="flex-start">
                        {renderFilterElement()}
                        <HStack spacing="1">
                            <IconButton
                                aria-label="Clear"
                                size="sm"
                                colorScheme="red"
                                onClick={clear}
                            >
                                <MdCancel size={18}/>
                            </IconButton>
                            <IconButton
                                aria-label="Save"
                                size="sm"
                                onClick={save}
                                colorScheme="green"
                            >
                                <AiFillCheckCircle size={18}/>
                            </IconButton>
                        </HStack>
                    </VStack>
                )}
            </MenuList>
        </Menu>
    );
};
