import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";

export default function Channel({ value, column, row}) {
    return (
        <Flex>
            <Box ml='3'>
                <Text fontWeight='bold'>
                    #{value.name}
                </Text>
                <Text fontSize='sm'>Channel Id: {value.id}</Text>
            </Box>
        </Flex>
    )
}