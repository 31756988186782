import {useMutation} from "react-query";
import {useAuth0} from '@auth0/auth0-react';

export function useLogout() {
    const { logout } = useAuth0();

    return useMutation(
        () => logout({
            returnTo: "https://www.banwaves.xyz",
        }),
    )
}