import {
    Alert, AlertDescription,
    AlertIcon,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Select,
    useDisclosure
} from "@chakra-ui/react";
import React, {useState} from "react";
import {FiPlus} from "react-icons/fi";
import {InputField} from "../../../../components/fields/impl/InputField";
import toast from "react-hot-toast";
import * as guild from "../../../../api/internal";
import {useAuth0} from "@auth0/auth0-react";


export default function NewFilterModal(props){
    const { serverId } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filterType, setFilterType] = useState("username");
    const [filter, setFilter] = useState(undefined);
    const [filterAction, setFilterAction] = useState("none");
    const [errorFilter, setErrorFilter] = useState(false);
    const auth0 = useAuth0();

    const handleSubmit = (e) => {
        e.preventDefault();
        if(filter === undefined){
            setErrorFilter(true);
        }
        if(filterType !== undefined && filter !== undefined){
            toast.promise(
                guild.createFilter(serverId, filterType, filter, filterAction, auth0).then(r => {
                    setTimeout(() => window.location.href = `/guild/${serverId}/filters`, 500);
                }),
                {
                    loading: 'Creating filter...',
                    success: 'Filter Created!',
                    error: 'Error created filter',
                }
            );
        }
    }

    return (
        <>
            <Button h='44px'
                    w={{ lg: "390px" }}
                    borderRadius='16px' onClick={onOpen} variant='action' mx={"auto"}>
                <FiPlus style={{marginRight: "5px"}} />Add new filter
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>👇 Create filter:</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel htmlFor='content'>Type</FormLabel>
                            <Select onChange={(e) => setFilterType(e.target.value)}>
                                <option value='username' style={{color: "white"}}>Username</option>
                                <option value='avatarHash' style={{color: "white"}}>Avatar hash</option>
                            </Select>
                            <FormHelperText>Filter type</FormHelperText>
                        </FormControl>
                        <FormControl marginTop="20px" isInvalid={errorFilter}>
                            <FormLabel htmlFor='content'>Filter</FormLabel>
                            <InputField
                                id='filter'
                                variant="main"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            {!errorFilter ? <FormHelperText>Filter</FormHelperText> : <FormErrorMessage>Filter is required.</FormErrorMessage>}
                        </FormControl>
                        <FormControl marginTop="20px" >
                            <FormLabel as='legend'>Action type</FormLabel>
                            <RadioGroup defaultValue='none' value={filterAction} onChange={setFilterAction}>
                                <HStack spacing='24px'>
                                    <Radio value='none'>No Action</Radio>
                                    <Radio value='ban'>Ban</Radio>
                                    <Radio value='kick'>Kick</Radio>
                                    <Radio value='warn'>Warn</Radio>
                                </HStack>
                            </RadioGroup>
                            <FormHelperText>Select action type.</FormHelperText>
                        </FormControl>

                        <Alert status='info' size={'sm'}>
                            <AlertIcon />
                            <AlertDescription>Action Type only trigger when someone join the server or change his name/pfp.</AlertDescription>
                        </Alert>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme={'green'} onClick={handleSubmit}>Create</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}