import {fetchAuto} from "../../utils";

/**
 * get charges on BanWaves
 */
export async function getAllCharges(auth0) {
    return fetchAuto(`/admin/checkouts`, auth0,{
        toJson: true
    });
}

/**
 * get premium infos of server
 */
export async function getCharge(serverId, chargeId, auth0) {
    return fetchAuto(`/gateaway/${serverId}/charge/${chargeId}`, auth0,{
        toJson: true
    });
}