import React, {useContext} from "react";
import {DeleteIcon, SearchIcon} from "@chakra-ui/icons";
import {IconButton, Stack} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {GuildContext} from "../../../../contexts/guild/GuildContext";
import {GuildScanContext} from "../../../../contexts/guild/GuildScanContext";
import toast from "react-hot-toast";
import * as guild from "../../../../api/internal";


export default function ScanDetailActionsButtons({ value, column, row}) {
    const auth0 = useAuth0();
    const {id: serverId} = useContext(GuildContext);
    const navigate = useNavigate();
    const scanId = useContext(GuildScanContext);

    const deleteHashFromScan = (value) => {
        toast.promise(
            guild.deleteScanEntries(serverId, scanId, value, auth0).then(r => {
                setTimeout(() => window.location.reload(), 2000);
            }),
            {
                loading: 'Deleting value...',
                success: `The value ${value} have been deleted from this scan.`,
                error: 'Error while deleting value from scan!',
            }
        );
    }

    return (
        <Stack direction='row' spacing={2} justify="flex-end">
            <IconButton
                colorScheme='messenger'
                aria-label='See accounts'
                size='lg'
                className={"mx-1"}
                onClick={() => {
                    navigate(`hash/${row.original.value}`)
                }}
                icon={<SearchIcon />}
            />
            <IconButton
                colorScheme='red'
                aria-label='Delete result from Scan.'
                size='lg'
                className={"mx-1"}
                icon={<DeleteIcon />}
                onClick={() => {
                    deleteHashFromScan(row.original.value)
                }}
            />
        </Stack>
    )
}