// Chakra imports
import {Box, Center, Portal, Spinner, Stack, Text} from "@chakra-ui/react";
// Layout components
import React, {useContext} from "react";
import {Navigate, Outlet, Route} from "react-router-dom";
import {UserDataContext, UserDataProvider} from "contexts/UserDataContext";
//routes
import {Suspense} from "react";
import ErrorBoundary from "../../components/ErrorBoundary";
import {PageInfoProvider} from "../../contexts/PageInfoContext";
import Navbar from "../../components/navbar/NavbarAdmin";
import StaffSidebar from "../../components/sidebar/StaffSidebar";

import routes from "../../staff_routes.js";
import PermissionDenied from "../../views/info/PermissionDenied";

function getRoutes(routes) {
    return routes.map((route, key) => {
        return (<><Route path={route.path} element={route.component}  key={key} />{route.children && getRoutes(route.children)}</>)
    });
}

function RouteWrapper({children}) {
    const {permissions} = useContext(UserDataContext)

    if(!permissions.some(permission => permission.permission_name === 'admin:full-read')){
        return (<PermissionDenied />)
    }


    return (
        <Box>
            <PageInfoProvider>
                <StaffSidebar routes={routes} display="none"/>
                <Box
                    float="right"
                    minHeight="100vh"
                    height="100%"
                    position="relative"
                    maxHeight="100%"
                    w={{base: "100%", xl: "calc( 100% - 290px )"}}
                    maxWidth={{base: "100%", xl: "calc( 100% - 290px )"}}
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease"
                    overflow="hidden"
                >
                    <Portal>
                        <Box>
                            <Navbar/>
                        </Box>
                    </Portal>

                    <Box
                        mx="auto"
                        p={{base: "20px", md: "30px"}}
                        pe="20px"
                        minH="100vh"
                        pt="50px"
                    >
                        <ErrorBoundary>
                            <Suspense fallback={<Center height="100vh">
                                <Stack direction="column" align="center">
                                    <Spinner size="lg"/>
                                    <Text>Loading...</Text>
                                </Stack>
                            </Center>}>
                                {children}
                            </Suspense>
                        </ErrorBoundary>
                    </Box>
                </Box>
            </PageInfoProvider>
        </Box>
    )
}

export default function StaffBoard() {
    return (
        <UserDataProvider>
            <RouteWrapper>
                <Outlet/>
            </RouteWrapper>
        </UserDataProvider>
    );
}

export function StaffRoutes() {
    return (
        <>
            <Route path="*" element={<Navigate replace to="dashboard"/>}/>
            {getRoutes(routes)}
        </>
    )
}