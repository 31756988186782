import {fetchAuto} from "../../utils";

/**
 * get bans of server
 */
export async function getBans(serverId, auth0) {
    return fetchAuto(`/guild/${serverId}/bans`, auth0,{
        toJson: true
    });
}

/**
 * get members of server
 */
export async function getInvites(serverId, auth0) {
    return await fetchAuto(`/guild/${serverId}/invites`, auth0,{
        toJson: true
    });
}

/**
 * get members of server
 */
export async function getMembers(serverId, auth0) {
    const b = await fetchAuto(`/guild/${serverId}/roles`, auth0,{
        toJson: true
    })

    const a = await fetchAuto(`/guild/${serverId}/members`, auth0,{
        toJson: true
    });
    
    a.forEach((e, i) => {
        e.serverRoles = []
        e.roles.forEach((role) => {
            a[i].serverRoles.push(b.find((r) => r.id === role))
        })
    })

    return a
}

/**
 * get webhooks of a server
 */
export async function getWebHooks(serverId, auth0) {
    const channels = await fetchAuto(`/guild/${serverId}/channels`, auth0,{
        toJson: true
    })

    const webhooks = await fetchAuto(`/guild/${serverId}/webhooks`, auth0,{
        toJson: true
    });

    if(webhooks && !webhooks.code){
        webhooks.forEach((e, i) => {
            e.channel = [...channels.filter(channel => channel.id === e.channelId)][0];
        });
    }

    return webhooks;
}

/**
 * get filters of server
 */
export async function getFilters(serverId, auth0) {
    return fetchAuto(`/settings/${serverId}/filters`, auth0,{
        toJson: true
    });
}

/**
 * get status of banwaves
 */
export async function getStatus(auth0) {
    return fetchAuto(`/connected`, auth0,{
        toJson: true
    });
}

/**
 * get backups of server
 */
export async function getBackups(serverId, auth0) {
    return fetchAuto(`/settings/${serverId}/backups`, auth0,{
        toJson: true
    });
}

/**
 * get server reports by report type;
 */
export async function getReports(serverId, reportType, auth0) {
    return fetchAuto(`/report/${serverId}/${reportType}`, auth0,{
        toJson: true
    });
}

/**
 * get every reports by report type;
 */
export async function getReportsAdmin(serverId, reportType, auth0) {
    return fetchAuto(`/report/${serverId}/${reportType}/admin`, auth0,{
        toJson: true
    });
}

/**
 * get backup of server
 */
export async function getBackup(serverId, backupId, auth0) {
    return fetchAuto(`/settings/${serverId}/backup/${backupId}`, auth0,{
        toJson: true
    });
}

/**
 * get premium infos of server
 */
export async function getPremium(serverId, auth0) {
    return fetchAuto(`/settings/${serverId}/premium`, auth0,{
        toJson: true
    });
}

/**
 * get filter of server by ID
 */
export async function getFilter(serverId, filterId, auth0) {
    return fetchAuto(`/settings/${serverId}/filter/${filterId}`, auth0, {
        toJson: true
    });
}

/**
 * create filter of server
 */
export async function createFilter(serverId, type, filter, action, auth0) {
    return fetchAuto(`/settings/${serverId}/filters/${type}`, auth0,{
        toJson: true,
        method: 'POST',
        body: JSON.stringify({
            filter: filter,
            action: action
        })
    });
}

/**
 * create report for a server
 */
export async function createReport(serverId, plateform, type, value, auth0) {
    return fetchAuto(`/report/${serverId}/${plateform}/${type}`, auth0,{
        toJson: true,
        method: 'POST',
        body: JSON.stringify({
            value: value
        })
    });
}

/**
 * create report opensea for a server
 */
export async function createReportOpenSea(serverId, value, auth0) {
    return fetchAuto(`/report/${serverId}/opensea`, auth0,{
        toJson: true,
        method: 'POST',
        body: JSON.stringify(value)
    });
}

/**
 * create filter of server
 */
export async function editFilter(serverId, filterId, type, filter, action, auth0) {
    return fetchAuto(`/settings/${serverId}/filter/${filterId}`, auth0, {
        toJson: true,
        method: 'PUT',
        body: JSON.stringify({
            filter: filter,
            action: action,
            type: type
        })
    });
}

/**
 * create filter of server
 */
export async function createBackups(serverId, name, description, auth0) {
    return fetchAuto(`/settings/${serverId}/backups`, auth0,{
        toJson: true,
        method: 'POST',
        body: JSON.stringify({
            name: name,
            description: description,
        })
    });
}

/**
 * start transaction for a server
 */
export async function startTransaction(serverId, planName, auth0) {
    return fetchAuto(`/gateaway/${serverId}/chargeCreate/${planName}`, auth0,{
        toJson: false,
        method: 'GET',
    });
}

/**
 * delete filters of server
 */
export async function deleteFilters(serverId, filterId, auth0) {
    return fetchAuto(`/settings/${serverId}/filter/${filterId}`, auth0,{
        toJson: false,
        method: 'DELETE',
    });
}

/**
 * delete transaction of server
 */
export async function deleteTransaction(serverId, transactionId, auth0) {
    return fetchAuto(`/gateaway/${serverId}/transaction/${transactionId}`, auth0,{
        toJson: false,
        method: 'DELETE',
    });
}

/**
 * delete backup of server
 */
export async function deleteBackup(serverId, backupId, auth0) {
    return fetchAuto(`/settings/${serverId}/backup/${backupId}`, auth0,{
        toJson: false,
        method: 'DELETE',
    });
}