import {fetchAuto} from "../utils";

export * from "./path/auth";
export * from "./path/guild";
export * from "./path/scans";
export * from "./path/gateaway";

/**
 * Get configuration settings values of a server
 * @return {values: any}
 */
export async function getSettings(serverId, auth0) {
    const settings = await fetchAuto(`/settings/${serverId}/settings`, auth0,{
        toJson: true
    });
    const roles = await fetchAuto(`/guild/${serverId}/roles`, auth0,{
        toJson: true
    });
    const channels = await fetchAuto(`/guild/${serverId}/channels`, auth0,{
        toJson: true
    });

    settings.values.roles = roles.filter(role => !role.tags?.botId).map(channel => {
        const returnChannel = {}

        returnChannel.id = channel.id;
        returnChannel.name = channel.name;
        returnChannel.icon = `role`;
        returnChannel.color = channel.color > 0 ? channel.color : `4343113`;
        return returnChannel;
    });

    settings.values.channels = channels.filter(channel => channel.type === 0).map(channel => {
        const returnChannel = {}

        returnChannel.id = channel.id;
        returnChannel.name = channel.name;
        returnChannel.icon = `channel`;
        return returnChannel;
    });


    return settings;
}

/**
 *
 * @return {Promise<any>} updated option values
 */
export async function updateLoggerOptions(serverId, changes, auth0) {
    const settings = await fetchAuto(`/settings/${serverId}/loggers`, auth0,{
        method: "PATCH",
        body: JSON.stringify(
            Object.fromEntries(changes)
        ),
        toJson: true
    });

    const roles = await fetchAuto(`/guild/${serverId}/roles`, auth0,{
        toJson: true
    });

    const channels = await fetchAuto(`/guild/${serverId}/channels`, auth0,{
        toJson: true
    });

    settings.roles = roles.map(channel => {
        const returnChannel = {}

        returnChannel.id = channel.id;
        returnChannel.name = channel.name;
        returnChannel.icon = `role`;
        returnChannel.color = channel.color > 0 ? channel.color : `4343113`;

        return returnChannel;
    });
    settings.channels = channels.filter(channel => channel.type === 0).map(channel => {
        const returnChannel = {}

        returnChannel.id = channel.id;
        returnChannel.name = channel.name;
        returnChannel.icon = `channel`;
        return returnChannel;
    });

    return settings;
}

/**
 *
 * @return {Promise<any>} updated option values
 */
export async function updateSettingsOptions(serverId, changes, auth0) {
    const settings = await fetchAuto(`/settings/${serverId}/settings`, auth0,{
        method: "PATCH",
        body: JSON.stringify(
            Object.fromEntries(changes)
        ),
        toJson: true
    });

    const roles = await fetchAuto(`/guild/${serverId}/roles`, auth0,{
        toJson: true
    });

    const channels = await fetchAuto(`/guild/${serverId}/channels`, auth0,{
        toJson: true
    });

    settings.roles = roles.map(channel => {
        const returnChannel = {}

        returnChannel.id = channel.id;
        returnChannel.name = channel.name;
        returnChannel.icon = `role`;
        returnChannel.color = channel.color > 0 ? channel.color : `4343113`;

        return returnChannel;
    });
    settings.channels = channels.filter(channel => channel.type === 0).map(channel => {
        const returnChannel = {}

        returnChannel.id = channel.id;
        returnChannel.name = channel.name;
        returnChannel.icon = `channel`;
        return returnChannel;
    });

    settings.selectedWhitelistedRoles = settings.roles.filter(r => settings.whitelistedRoles.includes(r.value))

    return settings;
}

/**
 * Normal server details
 */
export function getServerDetails(serverId, auth0) {
  return fetchAuto(`/guild/${serverId}/detail`, auth0,{
    toJson: true
  }).then(res => {
      return res;
  })
}

/**
 * get Notifications of server
 */
export async function getNotifications(serverId, auth0) {
  return fetchAuto(`/guild/${serverId}/notification`, auth0,{
    toJson: true
  })
}

/**
 * get Last scans
 */
export async function getLastScans(serverId, auth0, limit = 100) {
  return fetchAuto(`/scans/${serverId}/history?limit=${limit}`, auth0,{
    toJson: true
  })
}

/**
 * get awaiting scans
 */
export async function getWaitingScans(serverId, auth0) {
    return fetchAuto(`/scans/${serverId}/waiting`, auth0,{
        toJson: true
    })
}



/**
 * Get details of a scan
 */

export async function getScanDetails(serverId, scanId, auth0) {
  return fetchAuto(`/scans/${serverId}/get/${scanId}`, auth0,{
    toJson: true
  })
}

/**
 * get details from a hash
 */
export async function getHashDetailsByHash(serverId, scanId, hash, auth0) {
    const a = await fetchAuto(`/scans/${serverId}/get/${scanId}/find/${encodeURIComponent(hash)}`, auth0,{
        toJson: true
    })
    const b = await fetchAuto(`/guild/${serverId}/roles`, auth0,{
      toJson: true
    })

    a.forEach((e, i) => {
      e.serverRoles = []
      e.roles.forEach((role) => {
        a[i].serverRoles.push(b.find((r) => r.id === role))
      })
    })

    return a
}

export function isPremium(serverId, auth0) {
  return fetchAuto(
      `/settings/${serverId}/ispremium`,
      auth0,
      {
        throwError: false
      }
  ).then(res => res.ok)
}