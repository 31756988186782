/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Box, Button, Flex, Stack, useColorModeValue} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import {GuildContext} from "../../../../../contexts/guild/GuildContext";
import {useLocale} from "../../../../../utils/Language";
import {usePageInfo} from "../../../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {getHashDetailsByHash} from "../../../../../api/internal";
import {QueryHolderSkeleton} from "../../../../../contexts/components/AsyncContext";
import {NavLink, Outlet, useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import SearchTableUsers from "../../../../../components/table/SearchTable2";
import {AiOutlineArrowLeft} from "react-icons/ai";
import AvatarDisplay from "../../../../../components/table/cell/AvatarDisplay";
import ServerRoles from "../../../../../components/table/cell/roles/ServerRoles";

export default function ScanPanelHashDetails() {
    const locale = useLocale()
    const {sId, hashId} = useParams();
    const {id: serverId} = useContext(GuildContext)

    usePageInfo(locale({zh: "服務器儀表板", en: `Scan #${sId}. Value: ${hashId}`}))

    return <ScanHashDetails sId={sId} hashId={hashId} />
}

export function ScanHashDetails({ sId, hashId, roles }) {
    const {id: serverId} = useContext(GuildContext)
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const auth0 = useAuth0();

    const scanHistoryQuery = useQuery(
        ["server_hash_scan_details", serverId, sId, hashId],
        () => getHashDetailsByHash(serverId, sId, hashId, auth0),
    );

    const columns = [
        {
            Header: "Avatar",
            accessor: 'displayAvatarURL',
            Cell: AvatarDisplay,

        },
        {
            Header: "Display Name",
            accessor: 'displayName',
            filter: 'includes',
        },
        {
            Header: "User Id",
            accessor: 'userId',
            filter: 'includes',
        },
        {
            Header: "Roles",
            accessor: "serverRoles",
            Cell: ServerRoles,
        },
        {
            Header: "JOIN DATE",
            accessor: 'joinedTimestamp',
            Cell: ({value}) => new Date(value).toLocaleString()
        }
    ];

    const customRight = (
        <Stack direction='row' spacing={4}>
            <NavLink to={`/guild/${serverId}/scan/${sId}/details`}>
                <Button leftIcon={<AiOutlineArrowLeft/>} h='44px' w={{ lg: "300px" }} colorScheme={'facebook'} mx={"auto"}>
                    Back to scan overview
                </Button>
            </NavLink>
            <Button isDisabled={true} h='44px' w={{ lg: "300px" }} colorScheme={'facebook'} mx={"auto"}>
                Accounts: {scanHistoryQuery?.data?.length}
            </Button>
        </Stack>

    )

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column' mt="10px">
                    <Card px='0px'>
                        <QueryHolderSkeleton query={scanHistoryQuery}>
                            {() =>
                                <SearchTableUsers customRightSide={customRight} tableData={scanHistoryQuery.data} columnsData={columns}/>
                            }
                        </QueryHolderSkeleton>
                    </Card>
                </Flex>
            </Box>
            {/* Delete Product */}
            <Outlet/>
        </Box>
    );
}
