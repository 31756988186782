// chakra imports
import {Avatar, Box, Flex, HStack, Icon, Stack, Tag, Text, useColorModeValue} from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React, {useContext} from "react";
import {UserDataContext} from "../../../contexts/UserDataContext";
import {avatarToUrl} from "../../../api/discord/DiscordApi";
import {useBrandBg, useTextColor} from "../../../utils/colors";
import {NavLink} from "react-router-dom";
import {IoIosArrowRoundBack} from "react-icons/io";
import {Locale} from "../../../utils/Language";

// FUNCTIONS

export function StaffSidebarContent(props) {
    const { routes, width } = props;
    // SIDEBAR
    return (
        <Flex w={width} direction="column" height="100%" pt="25px" borderRadius="30px">
            <Brand />
            <Stack direction="column" mb="auto" mt="8px">
                <Box ps="20px">
                    <Links routes= {routes} />
                </Box>
            </Stack>
            <UserPreview />
        </Flex>
    );
}

function SidebarContent(props) {
    const { routes, width } = props;
    // SIDEBAR
    return (
        <Flex w={width} direction="column" height="100%" pt="25px" borderRadius="30px">
            <Brand />
            <Stack direction="column" mb="auto" mt="8px">
                <Box ps="20px">
                    <Links routes=  {routes} />
                </Box>
            </Stack>
            <UserPreview />
        </Flex>
    );
}

function UserPreview() {
    const {id, username, avatar, roles} = useContext(UserDataContext)
    const color                         = useTextColor()
    const bg                            = useColorModeValue("secondaryGray.400", "navy.600")
    const brand                         = useBrandBg()
    const role                          = roles[0] ?? {};

    const displayColorRole = {
        "rol_9i2XfFaVtlStcm8H": "teal",
        "rol_frWWkDOcGuGGW7v8": "green",
        "rol_gjf9kIYR3RHxu1s1": "cyan"
    }[role?.id];

    return (
        <HStack pos="relative" px={3} py={10} m={3} justify="center" rounded="lg" bgGradient={`linear(to-br, ${bg} 60%, ${brand}) 40%`}>
            <Box pos="absolute" mt="-40%" p={3} rounded="full" bg={bg}>
                <Text fontWeight="bold">
                    <Locale zh="Logged As" en="Logged As"/>
                </Text>
            </Box>
            <Box pos="absolute" top={0} left={0} m={4}>
              <NavLink to="../">
                <Icon as={IoIosArrowRoundBack} w="35px" h="35px"/>
              </NavLink>
            </Box>
            <HStack spacing="4" px="2">
                <Avatar color={color} name={username} src={avatarToUrl(id, avatar)} bg={brand}/>
                {
                    role.id ? (
                        <Flex direction="column">
                            <Text color={color} fontWeight={"bold"} fontWeight="medium">{username}</Text>
                            <Tag fontWeight="bold" backgroundColor={displayColorRole} textTransform="uppercase">
                                {role.name}
                            </Tag>
                        </Flex>
                    ) : (<Text color={color} fontWeight={"bold"} fontSize={23}>{username}</Text>)
                }

            </HStack>
        </HStack>
  )
}

export default SidebarContent;
