import React from "react";
import {Flex, Text,} from "@chakra-ui/react";
import {config} from "config/config";
import {Locale} from "../../utils/Language";
import {useTextColor} from "../../utils/colors";

export default function Footer({children}) {
    const textColor = useTextColor();

    return (
        <>
            <Flex
                zIndex='3'
                direction={{
                    base: "column",
                    xl: "row",
                }}
                alignItems={{
                    base: "center",
                    xl: "start",
                }}
                justifyContent='space-between'
                px={{base: "30px", md: "50px"}}
                pb='30px'>
                <Text
                    color={textColor}
                    textAlign={{
                        base: "center",
                        xl: "start",
                    }}
                    mb={{base: "20px", xl: "0px"}}>
                    &copy; {1900 + new Date().getYear()}
                    <Text as='span' fontWeight='500' ms='4px'>
                        <Locale
                            en={`
                            ${config.name}. All Rights Reserved.
                        `}
                        />
                    </Text>
                </Text>
                {children}
            </Flex>
        </>
    );
}
/*  */