import {Flex, Image, Text, useColorModeValue} from '@chakra-ui/react';

export default function Maintenance() {
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Flex direction='column' align='center' pt={{ sm: '125px', lg: '75px' }}>
            <Image src={'https://i.ibb.co/pbqj58r/ab-Ban-Wave1-02.png'} w='400px' maxW='90%' mt={{ base: '4vh', lg: '20vh' }} mb='10px' />
            <Text
                color={textColor}
                fontSize={{ base: '40px', lg: '46px' }}
                fontWeight='700'
                mb='30px'
                textAlign={{ base: 'center', md: 'start' }}>
                We’ll be back soon!
            </Text>
            <div>
                <p>Sorry for the inconvenience. We&rsquo;re performing some maintenance at the moment. If you need to you can always follow us on <a href="http://www.twitter.com/WavesBan">Twitter</a> for updates, otherwise we&rsquo;ll be back up shortly!</p>
                <p>&mdash; The BanWaves Team</p>
            </div>
        </Flex>
    );

}