import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Stack
} from "@chakra-ui/react";
import React, {useContext} from "react";
import {FaHammer} from "react-icons/fa";
import {GiBootKick} from "react-icons/gi";
import {useAuth0} from "@auth0/auth0-react";
import toast from "react-hot-toast";
import {runScan} from "../../api/internal";
import {GuildContext} from "../../contexts/guild/GuildContext";

export function RunScanModal({ scanId, isOpen, onClose, onConfirm, scanHistoryQuery }) {
    const auth0 = useAuth0();
    const {id: serverId} = useContext(GuildContext)

    function startScan(action) {
        toast.promise(
            runScan(serverId, scanId, auth0).then(r => {
                setTimeout(() => {
                    //scanHistoryQuery.refresh();
                    onConfirm();
                }, 1500);
            }),
            {
                loading: `Trying to run scan...`,
                success: 'Scan started. Bot is now processing!',
                error: 'Error while starting scan!',
            }
        );
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Action</ModalHeader>
                <ModalBody>
                    Please select an action to perform:
                </ModalBody>
                <ModalFooter>
                    <Stack direction='row' spacing={4}>
                        <Button leftIcon={<FaHammer />} h='44px' w={{ lg: "200px" }} colorScheme={'pink'}  mx={"auto"} onClick={() => startScan(`ban`)}>
                            Ban
                        </Button>
                        <Button leftIcon={<GiBootKick />} h='44px' w={{ lg: "200px" }} colorScheme={'whatsapp'}  mx={"auto"} onClick={() => startScan(`kick`)}>
                            Kick
                        </Button>
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}