// Custom components
import React from "react";
import {usePageInfo} from "../../../contexts/PageInfoContext";
import {useLocale} from "utils/Language";
import MiniStatistics from "components/card/data/MiniStatistics";
// Chakra imports
import {
    Alert,
    AlertIcon, AlertTitle,
    AspectRatio,
    Box, Flex,
    Icon,
    SimpleGrid, Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
// Custom components
import IconBox from "components/icons/IconBox";
import {
    MdBarChart,
    MdFileCopy,
} from "react-icons/md";
import Card from "../../../components/card/Card";
import {HiStatusOnline} from "react-icons/hi";
import {BiServer} from "react-icons/bi";

export default function StaffDashboard() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Staff Dashboard", en: "Staff Dashboard"}))

    return <StaffUserReports />
}

export function StaffUserReports() {
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgBar = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

    const textColorSecondary = useColorModeValue(
        "secondaryGray.600",
        "secondaryGray.500"
    );
    const textColorTertiary = useColorModeValue("secondaryGray.600", "white");

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 5 }} gap='20px' mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox w='56px' h='56px' bg={boxBg} icon={
                            <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                        } />
                    }
                    name='Members'
                    value= { 0 }
                />
                <MiniStatistics
                    startContent={
                        <IconBox w='56px' h='56px' bg={boxBg} icon={
                            <Icon w='32px' h='32px' as={HiStatusOnline} color={brandColor} />
                        } />
                    }
                    name='Online Members'
                    value={ 0 }
                />
                <MiniStatistics
                    startContent={
                        <IconBox w='56px' h='56px' bg={boxBg} icon={
                            <Icon w='32px' h='32px' as={BiServer} color={brandColor} />
                        } />
                    }
                    name='Server Boosts'
                    value={ 0 }
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={MdFileCopy} color='white' />
                            }
                        />
                    }
                    name='Channels'
                    value={ 0 }
                />
            </SimpleGrid>
            <SimpleGrid columns={{base: 1, lg: 2}} gap={5} mt={10}>
                <Card mb='20px' width="100%">
                    <Flex direction='column'>
                        <Flex mb='40px' justify='space-between' align='center'>
                            <Text fontSize='2xl' color={textColorPrimary} fontWeight='bold'>
                                BanWaves - Status
                            </Text>

                        </Flex>
                    </Flex>
                    <Flex direction='column'>
                        <Card px='0px'>
                            <Alert
                                status='success'
                                variant='subtle'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                                textAlign='center'
                                height='200px'
                            >
                                <AlertIcon boxSize='40px' mr={0} />
                                <AlertTitle mt={4} mb={1} fontSize='lg'>
                                    All Systems Operational
                                </AlertTitle>
                            </Alert>
                        </Card>
                    </Flex>
                </Card>
                <AspectRatio w='100%' maxW='100%' ratio={1130 / 636}>
                    <iframe
                        style={{ borderRadius: '30px' }}
                        src='https://www.youtube.com/embed/4a-WM6ab8Tk'
                        title='YouTube video player'
                        frame-border='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allow-full-screen
                    />
                </AspectRatio>
            </SimpleGrid>
        </Box>
    );
}