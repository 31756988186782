/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Box, Flex, Skeleton} from "@chakra-ui/react";

import {GuildContext} from "contexts/guild/GuildContext";
import {useLocale} from "utils/Language";
import {usePageInfo} from "contexts/PageInfoContext";
import {useQuery} from "react-query";
import {getBans} from "api/internal";
import {QueryHolder, QueryHolderSkeleton} from "contexts/components/AsyncContext";
import {Outlet} from "react-router-dom";
import Card from "components/card/Card";
import {useAuth0} from "@auth0/auth0-react";
import AvatarDisplay from "../../../../components/table/cell/AvatarDisplay";
import SearchTableUsers from "../../../../components/table/SearchTable2";

export default function BanPanel() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Bans", en: "Bans"}))

    return <BanDisplay />
}

export function BanDisplay() {
    const {id: serverId} = useContext(GuildContext)
    const auth0 = useAuth0();
    // Chakra Color Mode

    const bansQuery = useQuery(
        ["server_bans_query", serverId],
        () => getBans(serverId, auth0),
    );

    const scanColumns = [
        {
            Header: "Avatar",
            accessor: 'displayAvatarURL',
            Cell: AvatarDisplay,
        },
        {
            Header: "Display Name",
            accessor: 'username',
            filter: 'includes',
        },
        {
            Header: "User Id",
            accessor: 'id',
            filter: 'includes',
        },
        {
            Header: "Banned the",
            accessor: 'createdTimestamp',
            Cell: ({value}) => new Date(value).toLocaleString()
        }
    ]

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolderSkeleton query={bansQuery}>
                            {() =>
                                bansQuery.isLoading ? <Skeleton w="80%" h="5rem" rounded="xl" /> : <SearchTableUsers tableData={bansQuery.data} columnsData={scanColumns}/>
                            }
                        </QueryHolderSkeleton>
                    </Card>
                </Flex>
            </Box>
            <Outlet/>
        </Box>
    );
}
