import {fetchAuto} from "../../utils";

/**
 * Create a new scan
 */
export function createScan(serverId, data, auth0) {
    return fetchAuto(
        `/scans/${serverId}/start`,
        auth0,
        {
            method: "POST",
            body: JSON.stringify(data),
            throwError: false,
            toJson: true
        }
    );
}

/**
 * Delete a scan from a server
 */
export function deleteScan(serverId, scanId, auth0) {
    return fetchAuto(
        `/scans/${serverId}/delete/${scanId}`,
        auth0,
        {
            method: "DELETE",
            throwError: false
        }
    ).then(res => res.ok)
}

/**
 * Run scan
 */
export function runScan(serverId, scanId, auth0) {
    return fetchAuto(
        `/scans/${serverId}/run/${scanId}`,
        auth0,
        {
            method: "POST",
            throwError: true,
        }
    );
}

/**
 * Delete a webhook from a server
 */
export function deleteWebHook(serverId, webhookId, auth0) {
    return fetchAuto(
        `/guild/${serverId}/webhook/${webhookId}`,
        auth0,
        {
            method: "DELETE",
            throwError: false
        }
    ).then(res => res.ok)
}

/**
 * Delete one entries from a scan.
 */
export function deleteScanEntries(serverId, scanId, value, auth0) {
    return fetchAuto(
        `/scans/${serverId}/get/${scanId}/find/${value}`,
        auth0,
        {
            method: "DELETE",
            throwError: false
        }
    ).then(res => res.ok)
}