/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext, useState} from "react";

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Stack,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import {GuildContext} from "../../../../contexts/guild/GuildContext";
import {useLocale} from "../../../../utils/Language";
import {usePageInfo} from "../../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {deleteScan, getScanDetails} from "../../../../api/internal";
import {QueryHolderSkeleton} from "../../../../contexts/components/AsyncContext";
import {Outlet, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import SearchTableUsers from "../../../../components/table/SearchTable2";
import Members from "../../../../components/table/cell/Members";
import ScanDetailActionsButtons from "../../../../components/table/cell/actions/ScanDetailActionsButtons";
import {useAuth0} from "@auth0/auth0-react";
import {AiFillDelete} from "react-icons/ai";
import {GuildScanContext} from "../../../../contexts/guild/GuildScanContext";
import {FaPlay} from "react-icons/fa";
import {RunScanModal} from "../../../../components/modal/RunScanModal";

export default function ScanPanelDetails() {
    const locale = useLocale()
    const {sId} = useParams();

    if(!sId || isNaN(sId)) window.location.href = "/"

    usePageInfo(locale({zh: "Scan #"+sId, en: "Scan #"+sId}))
    
    return (
        <GuildScanContext.Provider value={sId}>
            <ScanDetails sId={sId}/>
        </GuildScanContext.Provider>
    )
}

export function ScanDetails({ sId }) {
    const {id: serverId} = useContext(GuildContext)
    // Chakra Color Mode
    const auth0 = useAuth0();

    const scanHistoryQuery = useQuery(
        ["server_scan_details", serverId, sId],
        () => getScanDetails(serverId, sId, auth0),
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const columns = [
        { Header: "DISPLAY NAME", accessor: "value", },
        {
            Header: "MEMBERS",
            accessor: "members",
            Cell: Members
        },
        {
            Header: "TOTAL MEMBER",
            accessor: "sum",
        },
        {
            Header: "ACTION",
            Cell: ScanDetailActionsButtons
        },
    ];

    const handleConfirm = () => {
        // perform the action here
        setIsModalOpen(false);
    }

    const handleClose = () => {
        setIsModalOpen(false);
    }

    function deleteCurrentScan(){
        // post data to api (/scans/:guild/start)
        toast.promise(
            deleteScan(serverId, sId, auth0).then(r => {
                setTimeout(() => window.location.href = `/guild/${serverId}/scan`, 1000);
            }),
            {
                loading: 'Deleting scan...',
                success: 'This scan have deleted.',
                error: 'Error while deleting the scan. Please report to server admin',
            }
        );
    }

    const customRight = (
        <Stack direction='row' spacing={4}>
            <RunScanModal
                isOpen={isModalOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                scanId={sId}
                scanHistoryQuery={scanHistoryQuery}
            />
            <Button leftIcon={<FaPlay />} h='44px' w={{ lg: "200px" }} colorScheme={'green'}  mx={"auto"} onClick={() => setIsModalOpen(true)}>
                Run
            </Button>
            <Button onClick={() => deleteCurrentScan()} rightIcon={<AiFillDelete/>} h='44px' w={{ lg: "200px" }} colorScheme={'red'} mx={"auto"}>
                Delete
            </Button>
        </Stack>

    )

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column' mt="10px">
                    <Card px='0px'>
                        <QueryHolderSkeleton query={scanHistoryQuery}>
                            {() =>
                                <SearchTableUsers customRightSide={customRight} tableData={scanHistoryQuery.data.data} columnsData={columns}/>
                            }
                        </QueryHolderSkeleton>
                    </Card>
                </Flex>


            </Box>
            {/* Delete Product */}
            <Outlet/>
        </Box>
    );
}
