import {Avatar, Box, Flex, Grid, useColorModeValue, useToast} from "@chakra-ui/react";
import {useLocation} from 'react-router-dom';

// Custom components
import Banner from "views/admin/profile/components/Banner";
import Settings from "components/card/Settings";
import ServerPicker from "views/admin/profile/components/ServerPicker";

// Assets
import React, {useContext, useState} from "react";
import {UserDataContext} from "contexts/UserDataContext";
import {avatarToUrl, bannerToUrl, useGuilds} from "api/discord/DiscordApi";
import {AiOutlineClose} from 'react-icons/ai'
import {AnimatePresence, motion} from 'framer-motion';
import Delete from "./components/Delete";
import RequestData from "./components/RequestData";
import {useAuth0} from "@auth0/auth0-react";

export default function Overview() {
    const user = useContext(UserDataContext);
    const location = useLocation();
    const toast = useToast();
    const auth0 = useAuth0();

    const {id, banner, name, avatar, picture} = user;
    const [show, setShow] = useState(false);

    const guildsQuery = useGuilds(auth0)
    const guilds = guildsQuery.data

    const borderColor = useColorModeValue(
        "white !important",
        "#111C44 !important"
    );

    if(location.search.includes('?error=404')){
        if (!toast.isActive('404')) {
            toast({
                id: '404',
                title: `Server doesn't exist`,
                description: "This server doesn't have banwaves installed on this server!",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    return (
        <Box pt={{base: "30px", md: "80px"}}>
            {/**/}
            <AnimatePresence>
                {show ? (
                    <motion.div animate={'show'}  key="modal" exit={'hide'} variants={{
                        show: {
                            opacity: 1,
                            y: 0,
                            transition: {
                                ease: 'easeOut',
                                duration: 0.3
                            }
                        },
                        exit: {
                            y: 20,
                            opacity: 0,
                            transition: {
                                ease: 'easeIn',
                                duration: 0.1
                            }
                        },
                        hide: {
                            y: 20,
                            opacity: 0

                        }}} initial={'hide'} width={{ base: "100%" }} >
                        <AiOutlineClose onClick={() => setShow(false)} style={{ width: "35px", height: "35px", marginLeft: "auto", marginRight: "0px", marginBottom: "10px", cursor: "pointer"}} />
                        <Grid templateColumns={{
                                base: "1fr",
                                lg: "1.34fr 1fr",
                            }}
                            templateRows={{
                                base: "repeat(2, 1fr)",
                                lg: "1fr",
                            }}
                            gap="20px">
                            <Flex direction='column'>
                                <Banner banner={banner && bannerToUrl(id, banner)} avatar={avatarToUrl(id, avatar)} name={name} joinedServers={guilds && guilds.filter(g => g.exist).length} servers={guilds && guilds.length} />
                                {/*<Sessions/> */}
                                <RequestData/>
                            </Flex>
                            {/* Column Right */}
                            <Flex direction='column'>
                                <Settings
                                    columns={{ sm: 1, md: 1, xl: 1 }}
                                    spacing={{ base: "20px", xl: "0px" }}
                                />
                                <Delete />
                            </Flex>
                        </Grid>
                    </motion.div>
                ) : (
                    <motion.div >
                        <Box width="100%" mb="-2.5em" display='absolute' >
                            <Flex>
                                <a onClick={() => setShow(true)} style={{ cursor: "pointer", height: "87px", width: "87px", marginLeft: "auto", marginRight: "25px", zIndex: "40" }}>
                                    <Avatar mx="auto" src={avatarToUrl(id, avatar)} h="87px" w="87px" border="4px solid" borderColor={borderColor}/>
                                </a>
                            </Flex>
                        </Box>
                        <ServerPicker query={guildsQuery} gridArea="1 / 1 / 2 / 2"/>
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>
    );
}