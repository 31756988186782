/*
=========================================================
* BanWaves - By LouLou
=========================================================

*/

import React, {useContext} from "react";

// Chakra imports
import {Box, Button, Flex, HStack, Select} from "@chakra-ui/react";

import {GuildContext} from "../../../contexts/guild/GuildContext";
import {useLocale} from "../../../utils/Language";
import {usePageInfo} from "../../../contexts/PageInfoContext";
import {useQuery} from "react-query";
import {getFilters} from "../../../api/internal";
import {QueryHolder} from "../../../contexts/components/AsyncContext";
import {Outlet} from "react-router-dom";
import Card from "../../../components/card/Card";
import NewFilterModal from "./component/NewFilterModal";
import {useAuth0} from "@auth0/auth0-react";
import SearchTableUsers from "../../../components/table/SearchTable2";
import FiltersType from "../../../components/table/cell/filters/FiltersType";
import FiltersActionButtons from "../../../components/table/cell/filters/FiltersActionButtons";
import PremiumFlex from "../../info/Premium";
import type {FilterElementProps} from "../../../components/table/interfaces";

export default function FiltersPanel() {
    const locale = useLocale()
    const {premium} = useContext(GuildContext)

    usePageInfo(locale({zh: "Filters", en: "Filters"}))


    return premium.premiumType === 0 ? <PremiumFlex/>: <FiltersDisplay />
}

function IconChevronDown(props: { size: number }) {
    return null;
}

export function FiltersDisplay() {
    const {id: serverId} = useContext(GuildContext)
    const auth0 = useAuth0();

    const filtersQuery = useQuery(
        ["server_settings_filters", serverId],
        () => getFilters(serverId, auth0),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
        }
    );

    const columns = [
        {
            id: "selection",
            enableSorting: false,
            enableColumnFilter: false,
            Header: function render({ getToggleAllPageRowsSelectedProps }) {
                return (
                    <HStack>
                        <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        <Button
                            size="xs"
                            color="red"
                            variant="outline"
                        >
                            Delete
                        </Button>
                    </HStack>
                );
            },
            Cell: function render({ row }) {
                return (
                    <HStack spacing="3">
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </HStack>
                );
            },
        },
        {
            Header: "TYPE",
            accessor: "type",
            enableColumnFilter: true,
            meta: {
                filterElement: function render(props: FilterElementProps) {
                    return (
                        <Select borderRadius="md" size="sm" placeholder="All Type"{...props} >
                            <option value="username">Username</option>
                            <option value="avatarHash">Profile pictures</option>
                        </Select>
                    );
                },
                filterOperator: "eq",
            },
        },
        {
            Header: "FILTER",
            accessor: "filter",
            meta: {
                filterOperator: "contains",
            },
        },
        {
            Header: "ACTION",
            accessor: "action",
            Cell: FiltersType,
            enableColumnFilter: true,
            meta: {
                filterElement: function render(props: FilterElementProps) {
                    return (
                        <Select borderRadius="md" size="sm" placeholder="All Status"{...props} >
                            <option value="published">published</option>
                            <option value="draft">draft</option>
                            <option value="rejected">rejected</option>
                        </Select>
                    );
                },
                filterOperator: "eq",
            },
        },
        {
            Header: "FILTER ACTION",
            Cell: FiltersActionButtons,
        },
    ];

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={filtersQuery}>
                            <SearchTableUsers customRightSide={<NewFilterModal serverId={serverId}/>} tableData={filtersQuery.data} columnsData={columns}/>
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            <Outlet/>
        </Box>
    );
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)