import React, {createContext} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "react-query";
import {QueryHolder} from "../components/AsyncContext";
import {getServerDetails} from "../../api/internal";
import NotFound from "../../views/info/Not_Found";

export const GuildContext = createContext({
  id: null,
  details: null,
});

export function GuildContextProvider({id, children}) {
    const auth0 = useAuth0();

    const query = useQuery(
        ["server_detail", id],
        () => getServerDetails(id, auth0),
        {
          refetchInterval: 60_000,
          refetchOnWindowFocus: false,
        }
    );

    return (
        <QueryHolder query={query}>
            <GuildContext.Provider value={query.data}>
              {query.data?.code >= 400 ? (<NotFound/>) : children }
            </GuildContext.Provider>
        </QueryHolder>
    );
}
