/* eslint-disable */
import React, {useContext} from "react";
import {NavLink, useLocation} from "react-router-dom";
// chakra imports
import {Badge, Box, Flex, HStack, Text, useDisclosure} from "@chakra-ui/react";
import {useBrandBg, useNoteColor, useTextColor} from "utils/colors";
import {useLocale} from "utils/Language";
import {CgShapeCircle} from "react-icons/cg";

import {PremiumModal} from "../../modal/PremiumModal";
import {GuildContext} from "../../../contexts/guild/GuildContext";

export function SidebarLinks({ routes }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <PremiumModal onClose={onClose} isOpen={isOpen}/>
            {routes.map((route, index) => (
                <>
                    <RouteItem key={index} route={route} onOpen={onOpen}/>
                </>
            ))}
        </>
    );
}

function RouteItem({route, onOpen}) {
  const location = useLocation();

  const active = location.pathname.endsWith(route.path);
  const includes = location.pathname.includes(route.path)

  return (
      <>
        <Item key={route.name} name={route.name} path={route.path} active={includes || active} icon={<Box me={2}>{route.icon}</Box>} pathname={location.pathname} premiumFeature={route.premium} onOpen={onOpen} betaFeature={route.beta}/>

        <Flex direction="column" pl={7}>
          {includes && route.items && route.items.map((item, key) => {
            const path = `${route.path}/${item.path}`;

            return <Item
                key={key}
                path={path}
                active={location.pathname.includes(path)}
                name={item.name}
                icon={item.icon ?? <CgShapeCircle />}
                pathname={location.pathname}
                premiumFeature={item.premium}
                betaFeature={item.beta}
                onOpen={onOpen}
            />
          })}
        </Flex>
      </>
  );
}

function Item({active, name, path, icon, pathname, premiumFeature = false, betaFeature = false, onOpen}) {
  let activeColor = useTextColor();
  let inactiveColor = useNoteColor()
  let brandColor = useBrandBg();

  const {id, premium} = useContext(GuildContext)
  const locale = useLocale();

  return (
      <NavLink onClick={(event) => {
          if(premiumFeature && premium.premiumType === 0){
              event.preventDefault();
              onOpen();
          }
      }} to={premiumFeature && premium.premiumType === 0 ? `/guild/${id}/premium` : path}>
        <HStack spacing={active ? "22px" : "26px"} py="5px">
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Box color={active ? brandColor : inactiveColor} me={2}>
              {icon}
            </Box>

            <Text
                me="auto"
                color={active ? activeColor : inactiveColor}
                fontWeight={active ? "bold" : "normal"}
            >
              {locale(name)}
            </Text>

              { premiumFeature ? (
                  <Box color={active ? brandColor : inactiveColor} me={2}>
                      <Badge ml='1' colorScheme='orange'>Premium</Badge>
                  </Box>
              ) : null}
              { betaFeature ? (
                  <Box color={active ? brandColor : inactiveColor} me={2}>
                      <Badge
                          colorScheme={'yellow'}
                          color={"yellow.500"}
                          fontSize='sm'
                          fontWeight='600'>
                          {'Beta'}
                      </Badge>
                  </Box>
              ) : null}
          </Flex>
          <Box
              h="36px"
              w="4px"
              bg={active ? brandColor : "transparent"}
              borderRadius="5px"
          />
        </HStack>
      </NavLink>
  );
}

export default SidebarLinks;
