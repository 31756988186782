/**
 * @type ConfigType
 */
export const config = {
    name: "BanWaves",
    loggers: detail => [
        {
            id: "channelCreate",
            name: "channelCreate",
            placeholder: "Select channel",
            description: "channelCreate is triggered when a text/voice/announcement/category/stage channel is created. It includes potential permission overwrites as well as the user who created the channel.",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["channelCreate"],
        },
        {
            id: "channelUpdate",
            name: "channelUpdate",
            placeholder: "Select channel",
            description: "channelUpdate is triggered when a text/voice/announcement/category/stage channel is updated. Potential logged changes are overwrite created/updated/deleted, bitrate, slowmode, and topic. The user who updated the channel and the reason (if any) are included.",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["channelUpdate"],
        },
        {
            id: "channelDelete",
            name: "channelDelete",
            placeholder: "Select channel",
            description: "channelDelete is triggered when a text/voice/announcement/category/stage channel is deleted. It includes potential permission overwrites, the user who deleted the channel, and the reason (if any).",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["channelDelete"],
        },
        {
            id: "guildBanAdd",
            name: "guildBanAdd",
            placeholder: "Select channel",
            description: "guildBanAdd is triggered when a member is banned from the server. It includes the action taker and the reason why (if any).",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildBanAdd"],
        },
        {
            id: "guildBanRemove",
            name: "guildBanRemove",
            placeholder: "Select channel",
            description: "guildBanRemove is triggered when a member is unbanned from the server. It includes the action taker and the reason why (if any).",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildBanRemove"],
        },
        {
            id: "guildRoleCreate",
            name: "guildRoleCreate",
            placeholder: "Select channel",
            description: "guildRoleCreate is triggered when a user creates a role. The role creator, name, color, mentionability, hoist, and permissions are included.",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildRoleCreate"],
        },
        {
            id: "guildRoleDelete",
            name: "guildRoleDelete",
            placeholder: "Select channel",
            description: "guildRoleDelete is triggered when a user deletes a role. The user who deleted the role, name, color, mentionability, hoist, and permissions are included.",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildRoleDelete"],
        },
        {
            id: "guildRoleUpdate",
            name: "guildRoleUpdate",
            placeholder: "Select channel",
            description: "guildRoleUpdate is triggered when a user changes the properties of a role. The user who updated the role, name, icon, color, mentionability, hoist, and permission overwrites are included.",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildRoleUpdate"],
        },
        {
            id: "guildUpdate",
            name: "guildUpdate",
            placeholder: "Select channel",
            description: "guildUpdate is triggered when the server has it\\'s settings updated. Supported updates are content filter, nsfw level, afk channel settings, banner, notification level, description, icon, two factor, locale, rules channel, official messages channel, and vanity code. The user who updated the server is included.",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildUpdate"],
        },
        {
            id: "messageDelete",
            name: "messageDelete",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["messageDelete"],
        },
        {
            id: "messageDeleteBulk",
            name: "messageDeleteBulk",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["messageDeleteBulk"],
        },
        {
            id: "messageUpdate",
            name: "messageUpdate",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["messageUpdate"],
        },
        {
            id: "guildMemberAdd",
            name: "guildMemberAdd",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildMemberAdd"],
        },
        {
            id: "guildMemberKick",
            name: "guildMemberKick",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildMemberKick"],
        },
        {
            id: "guildMemberRemove",
            name: "guildMemberRemove",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildMemberRemove"],
        },
        {
            id: "guildMemberUpdate",
            name: "guildMemberUpdate",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildMemberUpdate"],
        },
        {
            id: "guildMemberNickUpdate",
            name: "guildMemberNickUpdate",
            description: "",
            type: "id_enum",
            placeholder: "Select channel",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildMemberNickUpdate"],
        },
        {
            id: "guildMemberVerify",
            name: "guildMemberVerify",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildMemberVerify"],
        },
        {
            id: "voiceChannelLeave",
            name: "voiceChannelLeave",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["voiceChannelLeave"],
        },
        {
            id: "voiceChannelJoin",
            name: "voiceChannelJoin",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["voiceChannelJoin"],
        },
        {
            id: "voiceStateUpdate",
            name: "voiceStateUpdate",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["voiceStateUpdate"],
        },
        {
            id: "voiceChannelSwitch",
            name: "voiceChannelSwitch",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["voiceChannelSwitch"],
        },
        {
            id: "guildEmojisUpdate",
            name: "guildEmojisUpdate",
            description: "",
            placeholder: "Select channel",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["eventLogs"]["guildEmojisUpdate"],
        },
        {
            id: "guildMemberBoostUpdate",
            name: "guildMemberBoostUpdate",
            description: "",
            type: "id_enum",
            choices: detail["channels"],
            placeholder: "Select channel",
            value: detail["eventLogs"]["guildMemberBoostUpdate"],
        }
    ],
    antiscamdm: detail => [
        {
            id: "timeBeforeKick",
            name: "Auto-kick time:",
            description: "Auto-kick members who don't claim a role after a time. (Minutes)",
            type: "number",
            value: detail["timeBeforeKick"]
        },
        {
            id: "enableKickAfterTime",
            name: "Enable auto kick member:",
            description: "Enable or disable the auto kick of member who joined and didn't claim a role after time.",
            type: "boolean",
            value: detail["enableKickAfterTime"]
        },
        {
            id: "webhooksprotection",
            name: "WebHooks protection",
            description: "Enable or disable WebHooks protection. Once it's enabled, Webhooks creation will not longer be possible.",
            type: "boolean",
            value: detail["webhooksprotection"]
        }/*,
        {
            id: "welcomeFeature.dmMessage",
            name: "Message for DMs when open:",
            description: "Customise the message that the bot send in DMs when they are open.",
            type: "message_create",
            disabled: true,
        }*/
    ],
    settings: detail => [
        /*{
            id: "language",
            name: "Language",
            description: "Change the default language of BanWaves in your server.",
            type: "enum",
            choices: ['EN', 'FR'],
            value: detail["language"]
        },*/
        {
            id: "managerRoles",
            name: "Additional Bot Master Roles",
            description: "Roles that will also be considered as bot masters, even if they do not have the Administrator permission.",
            type: "id_enum",
            placeholder: "Choose roles that you will be as bot masters.",
            choices: detail["roles"],
            multiple: true,
            value: detail["managerRoles"],
        },
        /*{
            id: "logChannel",
            name: "Logs",
            description: "Channel on Discord where the bot will send the logs.",
            type: "id_enum",
            choices: detail["channels"],
            value: detail["logChannel"],
        },*/
        {
            id: "whitelistedRoles",
            name: "Additional whitelisted roles",
            description: "Roles that will also be considered as whitelisted and will never been present in any scan.",
            placeholder: "Choose roles that you will be as whitelisted.",
            type: "id_enum",
            choices: detail["roles"],
            multiple: true,
            value: detail["whitelistedRoles"],
        }
    ],
    automod: detail => [
        {
            id: "alllinks",
            name: "All Links",
            description: "Blocks all links in your server.",
            type: "boolean",
            value: detail["alllinks"]
        },
        {
            id: "inlinelinks",
            name: "Inline Links",
            description: "Blocks links that show custom text and hide the actual link.",
            type: "boolean",
            value: detail["inlinelinks"]
        },
        {
            id: "discordinvites",
            name: "Discord Invites",
            description: "Blocks all discord invites.",
            type: "boolean",
            value: detail["discordinvites"]
        }
    ],
    scanDash: detail => [
        {

        }
    ]
}