import {
    Box,
    Flex,
    HStack,
    Select,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import {SearchBar} from "components/navbar/searchBar/SearchBar";
import React, {useContext, useMemo} from "react";
import {useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable,} from "react-table";
import {PageButton} from "./shared/Button";
import {
    HiChevronDoubleLeft as ChevronDoubleLeftIcon,
    HiChevronDoubleRight as ChevronDoubleRightIcon,
    HiChevronLeft as ChevronLeftIcon,
    HiChevronRight as ChevronRightIcon
} from "react-icons/hi";
import {ColumnFilter, ColumnSorter} from "./column";
import {useLocation} from "react-router-dom";
import queryString from 'query-string';
import {SettingsContext} from "../../contexts/SettingsContext";

function SearchTable2(props) {
    const { columnsData, tableData, customRightSide, customCenterSide } = props;
    const {updateSettings, persistedStateList} = useContext(SettingsContext);

    const location = useLocation();
    const searchParams = queryString.parse(persistedStateList[location.pathname]);

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const persistedState = {
        sortBy: searchParams.sortBy ? JSON.parse(searchParams.sortBy) : [],
        filters: searchParams.filters ? JSON.parse(searchParams.filters) : [],
        pageSize: searchParams.pageSize ?? 25,
    };

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { sortBy: persistedState.sortBy, filters: persistedState.filters, pageSize: persistedState.pageSize },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        gotoPage,
        pageCount,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
        setPageSize,
        state,

    } = tableInstance;

    const { pageSize } = state;
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    React.useEffect(() => {
        const newSearchParams = {
            ...searchParams,
            sortBy: JSON.stringify(state.sortBy),
            filters: JSON.stringify(state.filters),
            pageSize: state.pageSize,
        };

        const newSearchString = queryString.stringify(newSearchParams);

        if (persistedStateList[location.pathname] !== newSearchString) {
            let persistedStateListObj;

            console.log('avant', persistedStateList);

            try {
                persistedStateListObj = persistedStateList;
            } catch (error) {
                console.error('Error parsing persistedStateList:', error);
                persistedStateListObj = {};
            }

            persistedStateList[location.pathname] = newSearchString;

            updateSettings({
                persistedStateList: persistedStateListObj
            })
        }
    }, [state.sortBy, state.filters, location.pathname, location.search, searchParams]);

    return (
        <Flex
            direction='column'
            w='100%'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex align={{ sm: "flex-start", lg: "flex-start" }}
                  justify={{ sm: "flex-start", lg: "flex-start" }}
                  w='100%'
                  px='22px'
                  mb='36px' gab={2}>
                <Box p='2'>
                    <SearchBar
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        h='44px'
                        w={{ lg: "390px" }}
                        borderRadius='16px'
                    />
                </Box>
                {customRightSide ? (
                    <>
                        <Spacer />
                        {customRightSide}
                    </>
                ) : null}
            </Flex>
            <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    pe='10px'
                                    key={index}
                                    borderColor={borderColor}>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color='gray.400'>
                                        {column.render("Header")}

                                        <HStack>
                                            <ColumnSorter column={column} />
                                            <ColumnFilter column={column}/>
                                        </HStack>
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);

                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let data = "";

                                    data = (
                                        <Text color={textColor} textColor={textColor} fontSize='sm' fontWeight='700'>
                                            {cell.value ?? 'Not Found: 404'}
                                        </Text>
                                    )

                                    return (
                                        <Td {...cell.getCellProps()} key={index} fontSize={{ sm: "14px" }} minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor={borderColor}>
                                            {cell.column.Cell.name === "defaultRenderer" ? data : cell.render('Cell')}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            { pageCount > 1 ? (
                <Flex
                    direction={{ sm: "column", md: "row" }}
                    justify='space-between'
                    align='center'
                    w='100%'
                    px={{ md: "22px" }}>
                    <Flex align='center'>

                        <Text
                            me='10px'
                            minW='max-content'
                            fontSize='sm'
                            color='gray.500'
                            fontWeight='normal'>
                            Show rows per page
                        </Text>
                        <Select
                            fontSize='sm'
                            variant='main'
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='250'>250</option>
                            <option value='500'>500</option>
                        </Select>
                    </Flex>
                    <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
                        <PageButton
                            className="rounded-l-md"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            <span className="sr-only">First one</span>
                            <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </PageButton>
                        <PageButton
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <span className="sr-only">Previous-one</span>
                            <ChevronLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </PageButton>
                        <PageButton
                            onClick={() => nextPage()}
                            disabled={!canNextPage
                            }>
                            <span className="sr-only">Next-one</span>
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </PageButton>
                        <PageButton
                            className="rounded-r-md"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            <span className="sr-only">Last one</span>
                            <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </PageButton>
                    </Stack>
                </Flex>
            ): null}
        </Flex>
    );
}

export default SearchTable2;
