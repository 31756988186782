import React from "react";

import {Avatar, Badge, Box, Flex, Text} from "@chakra-ui/react";

export default function Member({ value, column, row}) {
    return (
        <Flex>
            <Avatar src={row.original.displayAvatarURL} />
            <Box ml='3'>
                <Text textColor={'white'} fontWeight='bold'>
                    {row.original.displayName}
                    {
                        value.bot ? (((row.original.flags & 1 << 16) === 1 << 16) ? <Badge
                            colorScheme="purple"
                            variant="solid"
                            fontSize="sm"
                            fontWeight="bold"
                            px={2}
                            py={1}
                            rounded="md"
                        >
                            Verified
                        </Badge> : <Badge ml='1' colorScheme='cyan'>Bot</Badge>) : null
                    }
                </Text>
                <Text fontSize='sm'>Member Id: {row.original.userId ?? row.original.id}</Text>
            </Box>
        </Flex>
    )
}