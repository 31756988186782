import React from "react";
import {Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack} from "@chakra-ui/react";

function SliderInput({ textColorPrimary, value, onChange, disabled = false }) {
    return (
        <Flex>
            <Slider isDisabled={disabled} focusThumbOnChange={false} value={value} onChange={onChange} min={3} max={100}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb color={'gray'} fontSize='sm' boxSize='32px' children={value} />
            </Slider>
        </Flex>
    )
}

export default SliderInput;