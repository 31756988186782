import React from "react";

import {Icon} from "@chakra-ui/react";
import {BiCategory} from "react-icons/bi";

// Admin Imports
import StaffDashboard from "./views/staff/dashboard";
import StaffStatus from "./views/staff/status";
import StaffTransaction from "./views/staff/transactions";

/**
 * Public Routes that can access on sidebar
 * Path variables are not allowed (ex: feature/:id)
 */
const routes = [
    {
        name: {zh: "Dashboard", en: "Dashboard"},
        icon: <Icon as={BiCategory} width="20px" height="20px" color="inherit"/>,
        path: "dashboard",
        component: <StaffDashboard/>,
    },
    {
        name: {zh: "Status", en: "Status"},
        icon: <Icon as={BiCategory} width="20px" height="20px" color="inherit"/>,
        path: "status",
        component: <StaffStatus/>,
    },
    {
        name: {zh: "Transactions", en: "Transactions"},
        icon: <Icon as={BiCategory} width="20px" height="20px" color="inherit"/>,
        path: "transactions",
        component: <StaffTransaction/>,
    }
];

export default routes;
