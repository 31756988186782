/*eslint-disable*/
import React from "react";
import Footer from "./Footer";

export default function FooterAuth() {

  return (
    <Footer />
  );
}
