import React, {useContext} from "react";

// Chakra imports
import {Avatar, Flex, HStack, Stack, Text} from "@chakra-ui/react";

// Custom components
import {HSeparator} from "components/separator/Separator";
import {GuildContext} from "contexts/guild/GuildContext";
import {iconToUrl} from "api/discord/DiscordApi";
import {useTextColor} from "../../../utils/colors";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useTextColor()
  const {id} = useContext(GuildContext)

  return (
    <Flex align="center" direction="column" gap={5}>
        {
            id != null? (
                <GuildHeader logoColor={logoColor} />
            ) : (
                <HStack align="center" px={5}>
                    <Avatar name={'BanWaves'} src={'https://banwaves.xyz/assets/full_transparency-a6e83bc2.webp'} />
                    <Stack px={1}>
                        <Text fontWeight="extrabold" fontSize="2xl" color={logoColor}>
                            BanWaves
                        </Text>
                        <Text>
                            Powered by DiscordA.fr
                        </Text>
                    </Stack>
                </HStack>
            )
        }
      <HSeparator mb="20px" />
    </Flex>
  );
}

function GuildHeader({ logoColor}) {
    const {id, name, icon} = useContext(GuildContext)

    return (
        <HStack align="center" px={5}>
            <Avatar name={name} src={iconToUrl(id, icon)} />
            <Text fontWeight="extrabold" fontSize="2xl" color={logoColor}>
                {name}
            </Text>
        </HStack>
    )
}

export default SidebarBrand;
