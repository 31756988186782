import React from "react";

import {Avatar, Flex} from "@chakra-ui/react";


export default function AvatarDisplay({ value, column, row}) {
    return (
        <Flex align='center'>
            <Avatar src={value} />
        </Flex>
    )
}