// Custom components
import React, {useContext, useEffect, useMemo, useState} from "react";
import {usePageInfo} from "../../../contexts/PageInfoContext";
import {DataList} from "components/card/data/DataCard";
import {usePageState} from "utils/State";
import {useLocale} from "utils/Language";
import MiniStatistics from "components/card/data/MiniStatistics";
// Chakra imports
import {Box, Icon, SimpleGrid, useColorModeValue,} from "@chakra-ui/react";
// Assets
// Custom components
import IconBox from "components/icons/IconBox";
import {MdBarChart, MdFileCopy,} from "react-icons/md";
import {TbPremiumRights} from 'react-icons/tb'
import {HiStatusOnline} from "react-icons/hi";
import {BiServer} from "react-icons/bi";
import {GuildContext} from "../../../contexts/guild/GuildContext";
import Completion from "views/guild/dashboard/components/Completion";
import Course from "../../../components/card/Course";

export default function Dashboard() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Dashboard", en: "Dashboard"}))

    return <UserReports />
}

export function UserReports() {
    const detail = useContext(GuildContext)

    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    const [data, setData] = useState([]);


    useEffect(() => {
        fetch('https://static-feed.banwaves.xyz/settings-production.json')
            .then(response => response.json())
            .then(json => setData(json));
    }, []);

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 5 }} gap='20px' mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox w='56px' h='56px' bg={boxBg} icon={
                            <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                        } />
                    }
                    name='Members'
                    value= { detail.approximateMemberCount.toLocaleString('us-us') }
                />
                <MiniStatistics
                    startContent={
                        <IconBox w='56px' h='56px' bg={boxBg} icon={
                            <Icon w='32px' h='32px' as={HiStatusOnline} color={brandColor} />
                        } />
                    }
                    name='Online Members'
                    value={ detail.approximatePresenceCount.toLocaleString('us-us') }
                />
                <MiniStatistics
                    startContent={
                        <IconBox w='56px' h='56px' bg={boxBg} icon={
                            <Icon w='32px' h='32px' as={BiServer} color={brandColor} />
                        } />
                    }
                    name='Server Boosts'
                    value={ `${detail.premiumSubscriptionCount}` }
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={MdFileCopy} color='white' />
                            }
                        />
                    }
                    name='Channels'
                    value={ detail.channels.length }
                />
                <MiniStatistics startContent={
                    <IconBox
                        w='56px'
                        h='56px'
                        bg={boxBg}
                        icon={<Icon w='28px' h='28px' as={TbPremiumRights} color={brandColor} />}
                    />
                } name='Current subscription' value={detail.premium.name} />
            </SimpleGrid>

            <SimpleGrid columns={{base: 1, lg: 1}} gap={5} mt={10}>
                {/* Slider */}
                <SimpleGrid columns='1' gap='20px'>
                    {data?.infoBoxes?.map(item => (
                        <Course
                            title={item.title}
                            desc={item.description}
                            imageUrl={item.imageUrl}
                            buttons={item.buttons}
                        />
                    ))}
                </SimpleGrid>
                <Box minW="100px" gridArea='1 / 2 / 2 / 3'>
                    <Completion />
                </Box>
            </SimpleGrid>
        </Box>
    );
}

function Data({row, detail, advanced}) {
    const state = usePageState({
        advanced
    })

    const items = useMemo(
        () => row.items(detail, state),
        [detail, ...Object.values(state)]
    )

    return <DataList items={items} />
}