import {fetchAuto} from "../utils";
import {useQuery} from "react-query";

export async function getAccountInfo(auth0) {
  return await fetchAuto(`/users/@me`, auth0,{toJson: true})
}

export async function getTickets(auth0) {
    return await fetchAuto(`/users/tickets`, auth0,{toJson: true})
}

export async function getTicket(tId, auth0) {
    return await fetchAuto(`/users/ticket/${tId}`, auth0,{toJson: true})
}

export async function getGuilds(auth0) {
    return await fetchAuto(`/guilds`, auth0, {toJson: true})
}

export async function getGuild(id, auth0) {
    return await fetchAuto(`/guild/${id}`, auth0,{toJson: true})
}

export function bannerToUrl(id, hash) {
  return `https://cdn.discordapp.com/banners/${id}/${hash}?size=1024`;
}
export function avatarToUrl(id, hash) {
  return `https://cdn.discordapp.com/avatars/${id}/${hash}?size=512`;
}

export function iconToUrl(id, hash) {
  return `https://cdn.discordapp.com/icons/${id}/${hash}`;
}

export function useGuild(id, auth0) {
    return useQuery(
        ["guild", id],
        () => getGuild(id, auth0),
        {
            refetchOnWindowFocus: false
        }
    )
}

export function useGuilds(auth0) {
  return useQuery(
      "user_guilds",
      () => getGuilds(auth0),
      {
        refetchOnWindowFocus: false,
      }
  )
}