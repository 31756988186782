// Custom components
import React from "react";
import {usePageInfo} from "../../../contexts/PageInfoContext";
import {useLocale} from "utils/Language";
// Chakra imports
import {
    Box, Flex, Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
// Custom components
import Card from "../../../components/card/Card";
import {QueryHolder} from "../../../contexts/components/AsyncContext";
import SearchTableUsers from "../../../components/table/SearchTable2";
import {Outlet} from "react-router-dom";
import {useQuery} from "react-query";
import {getStatus} from "../../../api/internal";
import {useAuth0} from "@auth0/auth0-react";

export default function StaffStatus() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Staff Dashboard", en: "Staff Dashboard"}))

    return <StaffStatusReport />
}

export function StaffStatusReport() {
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgBar = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
    const auth0 = useAuth0();

    const textColorSecondary = useColorModeValue(
        "secondaryGray.600",
        "secondaryGray.500"
    );
    const textColorTertiary = useColorModeValue("secondaryGray.600", "white");

    const columns = [
        {
            Header: "ID",
            accessor: "id",
        },
        {
            Header: "AGENT TYPE",
            accessor: "agent",
        },
        {
            Header: "CONNECTED SINCE (DATE)",
            accessor: "connectedAt",
        },
        {
            Header: "RUNNING SHARDS",
            accessor: "shardList",
            Cell: ({value, column, row}) => {
                return (
                    <Text fontSize='sm' fontWeight='700'>
                        {value ?? 'No Shard'}
                    </Text>
                )
            },
        },
    ];

    const statusQuery = useQuery(
        ["banwaves_status"],
        () => getStatus(auth0),
    );

    console.log(statusQuery);

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={statusQuery}>
                            <SearchTableUsers tableData={statusQuery.data?.data ?? []} columnsData={columns}/>
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            <Outlet/>
        </Box>
    );
}