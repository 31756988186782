// Custom components
import React from "react";
import {usePageInfo} from "../../../contexts/PageInfoContext";
import {useLocale} from "../../../utils/Language";
// Chakra imports
import {Box, Flex, Skeleton, useColorModeValue,} from "@chakra-ui/react";
// Assets
// Custom components
import Card from "../../../components/card/Card";
import {QueryHolder} from "../../../contexts/components/AsyncContext";
import SearchTableUsers from "../../../components/table/SearchTable2";
import {Outlet} from "react-router-dom";
import {useQuery} from "react-query";
import {getAllCharges} from "../../../api/internal";
import {useAuth0} from "@auth0/auth0-react";

export default function StaffTransaction() {
    const locale = useLocale()

    usePageInfo(locale({zh: "Staff Transaction", en: "Staff Transaction"}))

    return <StaffTransactionReport />
}

export function StaffTransactionReport() {
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgBar = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
    const auth0 = useAuth0();

    const textColorSecondary = useColorModeValue(
        "secondaryGray.600",
        "secondaryGray.500"
    );
    const textColorTertiary = useColorModeValue("secondaryGray.600", "white");

    const columns = [
        {
            Header: "CODE",
            accessor: "code",
        },
        {
            Header: "ID",
            accessor: 'id'
        },
        {
            Header: "PRICE",
            accessor: 'pricing.local.amount'
        },
        {
            Header: "EXPIRE DATE",
            accessor: "expires_at",
        },
    ];

    const transationsQuery = useQuery(
        ["banwaves_transactioncharges"],
        () => getAllCharges(auth0),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <Box mb='20px' width="100%">
                <Flex direction='column'  mt="10px">
                    <Card px='0px'>
                        <QueryHolder query={transationsQuery}>
                            {() =>
                                transationsQuery.isLoading ? <Skeleton w="80%" h="5rem" rounded="xl" /> : <SearchTableUsers tableData={transationsQuery.data} columnsData={columns}/>
                            }
                        </QueryHolder>
                    </Card>
                </Flex>
            </Box>
            <Outlet/>
        </Box>
    );
}