import React, {useContext} from "react";

import {ChatIcon, DeleteIcon} from "@chakra-ui/icons";
import {
    Button,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import MessageBuildCard from "../../../fields/complex/MessageBuildCard";
import {GuildContext} from "../../../../contexts/guild/GuildContext";
import toast from "react-hot-toast";
import {deleteScan, deleteWebHook} from "../../../../api/internal";
import {useAuth0} from "@auth0/auth0-react";


export default function WebHooksActionButtons({ value, column, row}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {id: serverId} = useContext(GuildContext);
    const auth0 = useAuth0();

    const deleteWebHooks = (webhooksId) => {
        // post data to api (/scans/:guild/start)
        toast.promise(
            deleteWebHook(serverId, webhooksId, auth0).then(r => {
                setTimeout(() => window.location.reload(), 1500);
            }),
            {
                loading: 'Deleting webhook...',
                success: 'This webhook have been deleted.',
                error: 'Error while deleting the webhook. Please report to bot admin',
            }
        );
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>👇 Send Message:</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <MessageBuildCard onChange={data => console.log(data)} />
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme={'green'}>Send message</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/*<IconButton
                colorScheme='green'
                aria-label='Send message in webhook'
                size='lg'
                className={"mx-1"}
                icon={<ChatIcon />}
                onClick={onOpen}
                isDisabled={row.original.type === 2}
            />*/}
            <IconButton
                colorScheme='red'
                aria-label='Delete WebHook'
                size='lg'
                className={"mx-1"}
                icon={<DeleteIcon />}
                onClick={() => {
                    deleteWebHooks(row.original.id);
                }}
            />
        </>
    )
}